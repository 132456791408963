import React, { useState, useEffect, useRef } from "react";
import uploadImage from "../assets/uploadSales.png";
import addButton from "../assets/addGiveaway.png";
import { Modal, Spin, message } from 'antd';
import modalTic from "../assets/modalTick.png";
import SecondHeader from '../components/SecondHeader';
import axios from 'axios';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import sample from "../assets/sample2.png";
import remove from "../assets/salesRemove.png"

function Upload() {
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalSales, setTotalSales] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const promoterId = useSelector((state) => state.user.user?.userData.id);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isModalSuccess, setIsModalSuccess] = useState(false);
    console.log(location.state);
    const yesterdayDate = location.state?.yesterdayDate;

    console.log(yesterdayDate);

    const handleRemoveImage = () => {
        setSelectedFile(null);
    };

    if (!promoterId) {
        navigate("/")
    }

    const fileInputRef = useRef(null);

    const handleSalesChange = (event) => {
        setTotalSales(event.target.value);
    };


    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('promoterId', promoterId);
            formData.append('total_sales', totalSales);

            if (selectedFile) {
                formData.append('salesReportImage', selectedFile);
            }

            if (yesterdayDate) {
                formData.append('yesterdayDate', yesterdayDate);
            }

            setLoading(true);

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/uploadSales`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setIsModalOpen(true);
        } catch (error) {
            setLoading(false);
            console.error(error);
            message.error(error.response?.data.error || 'An error occurred');
        }
    };

    const handleModalOk = () => {
        setIsModalOpen(false);
        navigate("/");
    };

    const handleReportSampleClick = () => {
        setIsModalSuccess(true);
        // console.log("Report Sample clicked");
        // Add any additional logic you want to perform when "Report Sample" is clicked
    };

    const handleModalOk2 = () => {
        setIsModalSuccess(false);
        navigate("/");
    };


    return (
        <div className='User-CSS'>
            <SecondHeader text="Upload Sales Report" />
            <div className='upload-container'>
                <p className='text-center'>Upload a photo of Daily Closing Sales Report signed by Outlet Manager / Pharmacist </p>
                <div className="box-style-3 text-center">
                    <div className="box-style-3 text-center" style={{ boxShadow: 'none' }} onClick={() => fileInputRef.current.click()}>
                        {selectedFile && (
                            <div>
                                <img src={URL.createObjectURL(selectedFile)} alt="Sales Report" style={{ maxWidth: '100%' }} />
                            </div>
                        )}
                        {!selectedFile && (
                            <div><img style={{ width: '150px', height: '150px' }} src={uploadImage} />
                                <p style={{ margin: 0, fontWeight: 'bold' }}>Upload Your Sales Report</p>
                                <p style={{ margin: 0 }}>PNG, JPEG, HEIF or HEIC</p>
                                <p style={{ margin: 0, textDecoration: "underline", cursor: "pointer" }} onClick={handleReportSampleClick}>Report Sample</p>
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={(e) => handleFileUpload(e)}
                                /></div>
                        )}
                    </div>

                    {selectedFile && (
                        <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center' }}>
                            <img onClick={handleRemoveImage} src={remove} />
                        </div>
                    )}
                </div>

                <p className='font-weight-bold'>Total Sales</p>


                <input
                    name="sales"
                    placeholder="Amount"
                    type="text"
                    className="leave-input"
                    value={totalSales}
                    onChange={handleSalesChange}
                />
                {loading ?
                    (<Spin />) :
                    (<button onClick={handleSubmit}>Submit</button>)}

                <Modal
                    visible={isModalOpen}
                    onOk={handleModalOk}
                    onCancel={() => setIsModalOpen(false)}
                    footer={null}
                    style={{
                        // backgroundColor: '#f7f7f7',
                        borderRadius: '20px'
                    }}
                    width={350}
                >
                    <div className='text-center'>
                        <img style={{ width: "20%" }} src={modalTic} />
                        <p className='font-weight-bold mt-2' style={{ fontSize: '15px' }}>Success!</p>
                        <p>Sales Report has been successfully submitted.</p>
                        <button onClick={() => handleModalOk()}>Done</button>
                    </div>
                </Modal>

                <Modal
                    open={isModalSuccess}
                    onOk={handleModalOk2}
                    onCancel={() => setIsModalSuccess(false)}
                    footer={null}
                    style={{
                        // backgroundColor: '#f7f7f7',
                        borderRadius: '20px'
                    }}
                    width={350}
                >
                    <div className="text-center">
                        <p className="Header" style={{ textAlign: 'center', marginTop: '10px' }}>Report Sample</p>
                        <p>Sample of physical report:</p>
                        <img src={sample} alt="Sample" style={{ width: '100%', marginTop: '20px' }} />
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default Upload
