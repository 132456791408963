import collapseIcon from "../assets/collapseIcon.png"
import centrum from "../assets/centrum.png"
import { NavLink } from "react-router-dom"
import { useState, useEffect } from "react";
import SecondHeader from '../components/SecondHeader';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

function Summary() {
    const location = useLocation();
    const navigate = useNavigate();
    const promoterId = useSelector((state) => state.user.user?.userData.id);
    const [brand, setBrand] = useState([])
    const [completeBrand, setCompleteBrand] = useState([]);
    const [isHomeshelfCollapsed, setHomeshelfCollapsed] = useState(false);
    const [kpi, setKpi] = useState(null);
    const [totalBone, setTotalBone] = useState(null);
    const [totalSales, setTotalSales] = useState(null);
    const [totalCustomer, setTotalCustomer] = useState(null);
    const [totalBrandComplete, setTotalBrandComplete] = useState(null);
    const summaryDate = location.state.date;
    const displayDate = new Date(summaryDate);
    const formattedDate = new Date(displayDate).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).toUpperCase()
       
    const [completion, setCompletion] = useState(0);
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [totalHomeshelf, setTotalHomeshelf] = useState(null);
    const [roadshowSales, setRoadshowDetails] = useState(null);

    console.log(totalBone, totalSales, totalCustomer, totalBrandComplete);

    useEffect(() => {
        const updateCompletion = () => {
            let newCompletion = 0;

            if (totalBone !== 0 ) newCompletion++;
            if (totalSales !== 0 ) newCompletion++;
            if (totalCustomer !== 0 ) newCompletion++;
            if (totalBrandComplete !== 0 ) newCompletion++;

            setCompletion(newCompletion);

            // Calculate completion percentage
            const totalItems = 4; // Total number of items
            const percentage = (newCompletion / totalItems) * 100;
            setCompletionPercentage(percentage);
        };

        updateCompletion();
    }, [totalBone, totalSales, totalCustomer, totalBrandComplete]);

    const [bone, setBone] = useState(null);
    const [sales, setSales] = useState(null);

    if (!promoterId) {
        navigate("/")
    }



    useEffect(() => {
        const getBrand = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getBrand`, { promoterId });
                setBrand(response.data.getBrand);
                setTotalHomeshelf(response.data.brandNo);
            } catch (error) {
                console.error(error);
            }
        };

        getBrand();
    }, []);

    useEffect(() => {
        const checkToken = async () => {
            try {
                const token = localStorage.getItem('haleon-token');

                if (!token) {
                    navigate("/login");
                }

                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getSummary`, { token, summaryDate, promoterId });
                
                console.log(response);
                console.log("test");
                setTotalBone(response.data.data.totalBone);
                setTotalSales(response.data.data.totalSales);
                setTotalCustomer(response.data.data.totalCustomer);
                setCompleteBrand(response.data.data.findBrandComplete);
                setKpi(response.data.data.userKpi);
                setTotalBrandComplete(response.data.data.totalBrandComplete);
                setBone(response.data.data.boneInfo);
                setSales(response.data.data.salesInfo);
                setRoadshowDetails(response.data.data.roadshowInfo);

            } catch (error) {
                console.error(error);
            }
        };

        checkToken();
    }, []);

    const handleHomeshelfCollapse = () => {
        setHomeshelfCollapsed(!isHomeshelfCollapsed);
    };

    
    return (
        <div className='User-CSS'>
            <SecondHeader text="Summary" />
            <div className="summary-container">

                <div className="Header text-center"> {formattedDate} </div>

                <div className="d-flex justify-content-center" style={{ gap: '20px' }}>
                    <div className="box-style">
                        <div className="text-number">{completion}<span style={{ fontSize: "20px" }}>/4</span></div>
                        <p className="sub-text" style={{ fontSize: '12px', margin: 0, fontWeight: 100 }}>Completed Task(s)</p>
                    </div>
                    <div className="box-style">
                        <div className="text-number">{completionPercentage}%</div>
                        <p className="sub-text" style={{ fontSize: '12px', margin: 0, fontWeight: 100 }}>Completion rate</p>
                    </div>
                </div>
                <div>
                    <div className="box-style-3">
                        <div onClick={() => navigate("/bone", { state: { bone } })} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ margin: 0 }} className="summary-header text-left">Bone Check</p>
                            <p style={{ margin: 0 }} className="text text-right">{totalBone ? totalBone : "-"}/{kpi ? kpi.bone_check : '-'} <img src={collapseIcon} /></p>
                        </div>

                    </div>
                    <div className="box-style-3">
                        <div onClick={() => navigate("/sales", { state: { sales, roadshowSales } })} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ margin: 0 }} className="summary-header text-left">Sales</p>
                            <p style={{ margin: 0 }} className="text text-right">RM{totalSales ? totalSales : "-"}/RM{kpi ? kpi.sales : "-"} <img src={collapseIcon} /></p>
                        </div>
                    </div>
                    <div className="box-style-3">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ margin: 0 }} className="summary-header text-left">Customer Reaches</p>
                            <p style={{ margin: 0 }} className="text text-right">{totalCustomer}/{kpi ? kpi.customer_reached : '-'}</p>
                        </div>
                    </div>
                    <div className="box-style-3">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ margin: 0 }} className="summary-header text-left">Homeshelf Display</p>
                            <p style={{ margin: 0 }} className="text text-right">{totalBrandComplete ? totalBrandComplete : "-"}/{totalHomeshelf ? totalHomeshelf : "-"}  <img src={collapseIcon} onClick={handleHomeshelfCollapse} /></p>
                        </div>
                    </div>
                </div>
                {isHomeshelfCollapsed && (
                    <div>
                        {brand.map((brand) => (
                            <div key={brand.id} className="box-style-3">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="mt-2">
                                    <div className="brand-text text-left">
                                        <img src={brand.image_url} alt={brand.name} style={{width: '50px'}} />
                                        {"      "}{brand.name}
                                    </div>
                                    {completeBrand.some((completeBrand) => completeBrand.id === brand.id) ? (
                                        <div className="brand-status text-right">Complete</div>
                                    ) : (
                                        <div className="brand-status text-right">Incomplete</div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Summary
