import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SecondHeader from '../components/SecondHeader';
import { useLocation } from 'react-router-dom';

function LeaveStatus() {
  const [leaveDetails, setLeaveDetails] = useState(null);
  const [signedUrl, setSignedUrl] = useState(null); 
  const location = useLocation();

  const leaveId = location.state.leaveId;

  useEffect(() => {
    const fetchLeaveDetails = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/displayLeave`, { leaveId });
        setLeaveDetails(response.data.getLeave);
        setSignedUrl(response.data.signedUrl); 
      } catch (error) {
        console.error('Error fetching leave details:', error);
      }
    };

    fetchLeaveDetails();
  }, [leaveId]);

  return (
    <div className='User-CSS'>
      <SecondHeader text="Summary" />
      <div className="status-leave-container">
        {leaveDetails && leaveDetails.length > 0 && (
          <div className="box-style-3">
            <div>
              <div className="font-weight-bold mt-3">Type Of Leave:</div>
              <div className="mt-2">{leaveDetails[0].type}</div>
            </div>
            <div>
              <div className="font-weight-bold mt-3">Reason:</div>
              <div className="mt-2">{leaveDetails[0].reason}</div>
            </div>
            {signedUrl && (
              <div>
                <div className="font-weight-bold mt-3">Photo Of Medical Certificate:</div>
                <img style={{width: '250px'}} src={signedUrl} alt="Medical Certificate" />
              </div>
            )}
            <div>
              <div className="font-weight-bold mt-3">Status:</div>
              <div className="mt-2">{leaveDetails[0].status}</div>
            </div>
          </div>
        )}
        {!leaveDetails || leaveDetails.length === 0 && (
          <p>No leave details available.</p>
        )}
      </div>
    </div>
  );
}

export default LeaveStatus;
