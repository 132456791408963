import React from 'react'
import backArrow from "../assets/backArrow.png"
import { useNavigate } from "react-router-dom"

function SecondHeader({ text }) {

    const navigate = useNavigate();
    return (
        <div>
            <div className="header-top">
                <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                    <img onClick={() => navigate(-1)} src={backArrow} />
                    <p style={{textAlign: 'center', margin: 'auto'}}>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default SecondHeader
