import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import Sidebar from '../../components/Sidebar';

const WorkingRecord = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/workingRecords`)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []); 

  const handleFilter = () => {
    if (startDate && endDate) {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/workingRecords`, { startDate, endDate })
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Promoter Code', dataIndex: 'promoterCode', key: 'promoterCode' },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: 'checkin_time', dataIndex: 'checkin_time', key: 'checkin_time' },
    { title: 'checkin_status', dataIndex: 'checkin_status', key: 'checkin_status' },
    { title: 'checkin_remarks_time', dataIndex: 'checkin_remarks_time', key: 'checkin_remarks_time' },
    { title: 'lunch_time', dataIndex: 'lunch_time', key: 'lunch_time' },
    { title: 'dinner_time', dataIndex: 'dinner_time', key: 'dinner_time' },
    { title: 'checkout_time', dataIndex: 'checkout_time', key: 'checkout_time' },
    { title: 'checkout_status', dataIndex: 'checkout_status', key: 'checkout_status' },
    { title: 'checkout_remarks_time', dataIndex: 'checkout_remarks_time', key: 'checkout_remarks_time' }
  ];

  return (
    <div>
      <Sidebar />
      <div className="content">
      <div style={{ display: "flex", alignItems: "center" }}>
        <input type="date" id="startDate" name="startDate" value={startDate} onChange={e => setStartDate(e.target.value)} style={{ width: "200px" }} />
        <p style={{ margin: 0, padding: 0 }}> - </p>
        <input type="date" id="endDate" name="endDate" value={endDate} onChange={e => setEndDate(e.target.value)} style={{ width: "200px" }} />
        <button type="button" onClick={handleFilter} style={{ width: "200px" }}>Filter</button>
      </div>
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default WorkingRecord;
