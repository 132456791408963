import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Select } from 'antd';
import Sidebar from '../../components/Sidebar';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const LeaveApplication = () => {
  const [data, setData] = useState([]);
  const [filterStatus, setFilterStatus] = useState("Pending");
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isFilterClicked) {
      fetchData();
      setIsFilterClicked(false);
    }
    fetchData();
  }, [isFilterClicked, filterStatus]);

  const fetchData = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/applyLeaveAdmin`, { status: filterStatus })
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Promoter Code', dataIndex: 'promoterCode', key: 'promoterCode' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Type Of Leave', dataIndex: 'type', key: 'type' },
    { title: 'Reason', dataIndex: 'reason', key: 'reason' },
    { 
      title: 'Images', 
      dataIndex: 'image_url', 
      key: 'image_url',
      render: (image_url) => <img src={image_url} alt="Profile" style={{ width: '50px', height: '50px' }} />,
  },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (id) => (
        <Button onClick={() => handleActionClick(id)}>Validate</Button>
      ),
    },
  ];

  const handleActionClick = (id) => {
    navigate(`/admin/ValidateLeave`, { state: { id } });
  };

  const handleFilterChange = (value) => {
    setFilterStatus(value);
  };

  const handleFilterClick = () => {
    setIsFilterClicked(true);
  };

  return (
    <div>
      <Sidebar />
      <div className="content">
        <Select defaultValue="Pending" style={{ width: 120 }} onChange={handleFilterChange}>
          <Option value="Pending">Pending</Option>
          <Option value="Approved">Approved</Option>
          <Option value="Rejected">Rejected</Option>
        </Select>
        <Button type="primary" style={{width: 120, marginLeft: '20px'}} onClick={handleFilterClick}>
          Filter
        </Button>
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default LeaveApplication;
