import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, Checkbox, message } from 'antd';
import Sidebar from '../../components/Sidebar';

const EditBrand = () => {
    const location = useLocation();
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [centrum, setCentrum] = useState(false);
    const [scotts, setScotts] = useState(false);
    const [sensodyne, setSensodyne] = useState(false);
    const [caltrate, setCaltrate] = useState(false);
    const [polident, setPolident] = useState(false);
    const [panaflex, setPanaflex] = useState(false);
    const userId = location.state.id;


    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getUserBrand`, { userId: userId })
            .then(response => {
                setUserData(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [location]);


    const handleSaveClick = async () => {
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateBrand`, {
                id: userId,
                Centrum: centrum,
                Scotts: scotts,
                Sensodyne: sensodyne,
                Caltrate: caltrate,
                Polident: polident,
                Panaflex: panaflex,
            });

            message.success('User brands updated successfully');
        } catch (error) {
            console.error(error);
            message.error('Failed to update user brands');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Sidebar />
            <div className="content">
                <h2>Edit User Brands</h2>
                <Form>
                    <Form.Item label="Name">
                        <Input value={userData.name} readOnly />
                    </Form.Item>
                    <Form.Item label="Centrum">
                        <Checkbox
                            checked={centrum}
                            onChange={(e) => setCentrum(e.target.checked)}
                        >
                            Centrum
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Scotts">
                        <Checkbox
                            checked={scotts}
                            onChange={(e) => setScotts(e.target.checked)}
                        >
                            Scotts
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Sensodyne">
                        <Checkbox
                            checked={sensodyne}
                            onChange={(e) => setSensodyne(e.target.checked)}
                        >
                            Sensodyne
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Caltrate">
                        <Checkbox
                            checked={caltrate}
                            onChange={(e) => setCaltrate(e.target.checked)}
                        >
                            Caltrate
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Polident">
                        <Checkbox
                            checked={polident}
                            onChange={(e) => setPolident(e.target.checked)}
                        >
                            Polident
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Panaflex">
                        <Checkbox
                            checked={panaflex}
                            onChange={(e) => setPanaflex(e.target.checked)}
                        >
                            Panaflex
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={handleSaveClick} loading={loading}>
                            Save Changes
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default EditBrand;
