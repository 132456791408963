import React, { useState, useEffect } from 'react';
import { Modal, Spin, message } from 'antd';
import modalTic from "../assets/modalTick.png";
import SecondHeader from '../components/SecondHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';  // Make sure to import axios
import { useSelector } from "react-redux";
import addButton from "../assets/addGiveaway.png";

function Record() {
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRestockOpen, setIsRestockOpen] = useState(false);
    const [sku, setSku] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const promoterId = useSelector((state) => state.user.user?.userData.id);
    const certificateImage = location.state.homeshelfImage;
    const brandId = location.state.brandId;
    const userBrandId = location.state.userBrandId;
    const navigate = useNavigate();
    const [lowSKU, setLowSKU] = useState([])
    const [loading, setLoading] = useState(false);
    const yesterdayDate = location.state?.yesterdayDate;

    console.log(yesterdayDate);

    if (!promoterId) {
        navigate("/")
    }


    let lowStockSkuNames = [];

    const showModal = async () => {
        try {
            const formData = new FormData();
            formData.append('selectedItems', JSON.stringify(selectedItems));
            certificateImage.forEach((image, index) => {
                formData.append(`homeshelfImage[${index}]`, image);
            });
            formData.append('promoterId', promoterId);
            formData.append('brandId', brandId);
            formData.append('userBrandId', userBrandId);

            if (yesterdayDate) {
                formData.append('yesterdayDate', yesterdayDate);
            }

            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/restock`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            const lowStockSkus = selectedItems.filter(item => item.quantity < 12);

            if (lowStockSkus.length > 0) {
                lowStockSkuNames = lowStockSkus.map(item => sku.find(s => s.id === item.id)?.name || `SKU${item.id}`);
                setLowSKU(lowStockSkuNames)
                setIsRestockOpen(true);
            } else {
                setIsModalOpen(true);
            }

        } catch (error) {
            setLoading(false);
            console.error(error);
            message.error(error.response?.data.error || 'An error occurred');
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
        navigate("/");
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSelectChange = (event, index) => {
        const { value } = event.target;
        setSelectedItems((prevItems) => {
            const newItems = [...prevItems];
            newItems[index] = { ...newItems[index], id: value };
            return newItems;
        });
    };

    const handleQuantityChange = (event, index) => {
        const { value } = event.target;
        setSelectedItems((prevItems) => {
            const newItems = [...prevItems];
            newItems[index] = { ...newItems[index], quantity: value };
            return newItems;
        });
    };


    useEffect(() => {
        const getSku = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getSKU`, {
                    brandId: location.state.brandId,
                });
                setSku(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        getSku();
    }, [location.state.brandId]);

    const addNewSku = () => {
        setSelectedItems((prevItems) => [
            ...prevItems,
            {
                id: '', // set the default value to an empty string
                quantity: '',
            },
        ]);
    };

    return (
        <div className='User-CSS'>
            <SecondHeader text="Homeshelf Display" />
            <div className='record-container'>
                <p>Please record the quantity of stock</p>
                <div className='d-flex'>
                    <div>
                        {selectedItems.map((selectedItem, index) => (
                            <div key={index} className="d-flex">
                                <select
                                    name={`type_${index}`}
                                    className="leave-input"
                                    htmlFor={`type_${index}`}
                                    value={selectedItem.id}
                                    onChange={(e) => handleSelectChange(e, index)}
                                >
                                    <option value="" disabled>Select The Product</option>
                                    {sku.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                                <input
                                    name={`quantity_${index}`}
                                    placeholder="Enter quantity"
                                    type="text"
                                    className="leave-input"
                                    value={selectedItem.quantity}
                                    onChange={(e) => handleQuantityChange(e, index)}
                                    style={{ width: '50%', textAlign: 'center' }}
                                />
                            </div>
                        ))}
                        <img src={addButton} onClick={addNewSku} />
                    </div>
                </div>

                {loading ?
                    (<Spin />) :
                    (<button onClick={showModal}>Submit</button>)}

                <Modal
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    style={{
                        // backgroundColor: '#f7f7f7',
                        borderRadius: '20px'
                    }}
                    width={350}
                >
                    <div className='text-center'><div>
                        <img style={{ width: "20%" }} src={modalTic} alt="Tick" />
                        <p className='font-weight-bold mt-2' style={{ fontSize: '15px' }}>Success!</p>
                        <p>The photo and number of stocks have been submitted successfully.</p>
                        <button onClick={() => handleOk()}>Done</button>
                    </div>
                    </div>
                </Modal>

                <Modal
                    open={isRestockOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    style={{
                        // backgroundColor: '#f7f7f7',
                        borderRadius: '20px'
                    }}
                    width={350}
                >
                    <div className='text-center'>
                        <div>
                            {console.log("Low Stock SKUs:", lowSKU)}
                            <img style={{ width: "20%" }} src={modalTic} alt="Tick" />
                            <p className='font-weight-bold mt-2' style={{ fontSize: '15px' }}>Success!</p>
                            <p>Please inform store manager to replenish stock</p>
                            {lowSKU.map(name => (
                                <p style={{ color: 'red' }} key={name}>{name}</p>
                            ))}
                            <button onClick={() => handleOk()}>Done</button>
                        </div>
                    </div>
                </Modal>


            </div>
        </div>
    );
}

export default Record;
