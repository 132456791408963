import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'antd';
import Sidebar from '../../components/Sidebar';
import { useNavigate } from 'react-router-dom';

const SalesTracking = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/salesTracking`)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []); 

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Promoter Code', dataIndex: 'promoterCode', key: 'promoterCode' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Total Sales', dataIndex: 'total_sales', key: 'total_sales' },
    { title: 'LeaderId', dataIndex: 'leaderId', key: 'leaderId' },
    { title: 'Roadshow Amount', dataIndex: 'roadshow_amount', key: 'roadshow_amount' },
    { title: 'Roadshow Date', dataIndex: 'roadshow_date', key: 'roadshow_date' },
    { title: 'Roadshow Location', dataIndex: 'roadshow_location', key: 'roadshow_location' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { 
      title: 'Images', 
      dataIndex: 'image_url', 
      key: 'image_url',
      render: (image_url) => <img src={image_url} alt="Profile" style={{ width: '50px', height: '50px' }} />,
  },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (id) => (
        <Button onClick={() => handleActionClick(id)}>Validate</Button>
      ),
    },
    ];

    const handleActionClick = (id) => {
      navigate(`/admin/ValidateSales`, { state: { id } });
    };
  return (
    <div>
      <Sidebar />
      <div className="content">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default SalesTracking;
