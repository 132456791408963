import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from "../assets/HaleonLogo.png";
import { saveToken, saveUser, saveLocation } from "../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Spin, message } from 'antd';
import header from "../assets/loginHeader.png"

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ promoterCode: '', password: '' });
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [loading, setLoading] = useState(false);

  localStorage.removeItem('haleon-token');

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // console.log(process.env);

  useEffect(() => {
    const getLocation = async () => {
      try {
        if (navigator.geolocation) {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      } catch (error) {
        console.error('Error getting location:', error.message);
      }
    };

    getLocation();
  }, []);


  const handleLogin = async () => {
    try {
      console.log('Latitude:', location.latitude);
      console.log('Longitude:', location.longitude);

      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
        promoterCode: formData.promoterCode,
        password: formData.password,
        latitude: location.latitude,
        longitude: location.longitude,
      });

      dispatch(saveToken({ token: response.data.token }));
      localStorage.setItem('latitude', location.latitude);
      localStorage.setItem('longitude', location.longitude);

      navigate('/');
    } catch (error) {
      setLoading(false);
      console.log(error);
      console.error('Error logging in:', error.response?.data?.message || error.message);
      message.error(error.response?.data.error || 'An error occurred');
    }
  };

  return (
    <div className='User-CSS'>
      <img src={header} style={{ width: '100%' }} />
      <div className="attendance-container">
        <div className="text-center">
          {/* <img src={logo} alt="Haleon Logo" />
        <p className="font-weight-bold" style={{ fontSize: '22px' }}>Promoter Portal</p> */}
        </div>

        <p className="font-weight-bold mt-5 text-center" style={{ fontSize: '22px' }}>Login</p>

        <p style={{fontWeight: 'bold'}}>Promoter ID<span style={{color: 'red'}}>*</span></p>
        <input
          name="promoterCode"
          type="text"
          className="leave-input"
          value={formData.promoterCode}
          onChange={handleInputChange}
          style={{border: '1px solid black'}}
        />
        <p style={{fontWeight: 'bold'}}>Password<span style={{color: 'red'}}>*</span></p>
        <input
          name="password"
          type="password"
          className="leave-input"
          value={formData.password}
          onChange={handleInputChange}
          style={{border: '1px solid black'}}
        />

        {loading ?
          (<Spin />) :
          (<button className="mt-5" style={{ backgroundColor: 'black', borderRadius: 0, color: 'white' }} onClick={handleLogin}>
            Next
          </button>)}
      </div>
    </div>
  );
}

export default Login;
