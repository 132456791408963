import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import modalTic from "../assets/modalTick.png"
import SecondHeader from '../components/SecondHeader';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spin, message } from 'antd';

function Attendance() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationStatus, setLocationStatus] = useState(null);
  const [checkinStatus, setCheckinStatus] = useState(null);
  const [checkoutStatus, setCheckoutStatus] = useState(null);
  const [formData, setFormData] = useState({
    remarksLocation: "",
    remarksTime: "",
  });

  const promoterId = useSelector((state) => state.user.user?.userData.id);
  const locationId = useSelector((state) => state.user.user?.userData.schedule[0].location.id);
  const locationName = useSelector((state) => state.user.user?.userData.schedule[0].location.location);
  const scheduleId = useSelector((state) => state.user.user?.userData.schedule[0].id);

  if (!promoterId) {
    navigate("/")
  }

  const currentDate = new Date();
  const options = { hour: 'numeric', minute: 'numeric' };
  const timeNow = currentDate.toLocaleTimeString(undefined, options);

  const type = location.state;

  let statusCheckin = "";
  let statusCheckout = "";
  let locationInOut = "";

  if (checkinStatus === true) {
    statusCheckin = "On Time";
  } else {
    statusCheckin = "Late";
  }

  if (checkoutStatus === true) {
    statusCheckout = "On Time"
  } else {
    statusCheckout = "Check Out Early"
  }

  if (locationStatus === true) {
    locationInOut = "Approved"
  } else {
    locationInOut = "Rejected"
  }


  useEffect(() => {
    const checkLocation = async () => {
      try {
        const latitude = localStorage.getItem('latitude');
        const longitude = localStorage.getItem('longitude');

        if (!latitude && !longitude) {
          navigate("/login");
        }

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/checkLocation`, { latitude, longitude, promoterId });

        if (response.status === 200) {
          setLocationStatus(true);
        } else {
          setLocationStatus(false);
        }

      } catch (error) {
        console.error(error);
      }
    };

    checkLocation();
  }, []);

  useEffect(() => {
    const checkTime = async () => {
      try {
        const currentDate = new Date();
        const checkintime = currentDate.toLocaleTimeString();
        const checkouttime = currentDate.toLocaleTimeString();

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/checkTime`, { checkintime, promoterId, checkouttime });

        console.log(response);
        setCheckinStatus(response.data.checkinstatus);
        setCheckoutStatus(response.data.checkoutstatus);


      } catch (error) {
        console.error(error);
      }
    };

    checkTime();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    const inputValue = type === "file" ? event.target.files[0] : value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      console.log(type, checkinStatus, checkoutStatus, formData.remarksLocation, formData.remarksTime, locationInOut)


      if ((type === "Lunch" || type === "Dinner") && (locationInOut === "Rejected" && !formData.remarksLocation)) {
        console.log("error lunch and dinner no remarks")
      } else if ((type === "Clockin") && ((locationInOut === "Rejected" && !formData.remarksLocation) ||
        (checkinStatus === false && !formData.remarksTime))) {
        console.log(" errror clockin")
      }
      else if ((type === "Checkout") && ((locationInOut === "Rejected" && !formData.remarksLocation) ||
        (checkoutStatus === false && !formData.remarksTime))) {
        console.log(" errror clockout")
      } else {

      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/checkinAttendance`, {
        type,
        checkin_status: statusCheckin,
        checkout_status: statusCheckout,
        time: timeNow,
        promoterId,
        remarksLocation: formData.remarksLocation,
        remarksTime: formData.remarksTime,
        locationId,
        scheduleId,
        locationInOut
      });

      console.log("Leave application submitted:", response.data);
      showModal();
      }
    } catch (error) {
      console.error("Error submitting leave application:", error.response.data);
      message.error(error.response?.data.error || 'An error occurred');
      setLoading(false);
    }
  };

  return (
    <div className='User-CSS'>
      <SecondHeader text="Record Attendance" />
      <div className="attendance-container">
        {type === "Clockin" ? <p className="Header">Clock In</p> :
          type === "Lunch" ? <p className="Header">Lunch</p> :
            type === "Dinner" ? <p className="Header">Dinner</p> :
              <p className="Header">Clock Out</p>}

        <div className="attendance-box">
          <div className="font-weight-bold mt-1">Location:</div>
          <div>{locationName ? locationName : "-"}</div>
          <div className="font-weight-bold mt-1">My Clock In Location:</div>
          <div>{locationName ? locationName : "-"}</div>
          {locationStatus === true ? (
            <div className="attendance-status">Approved</div>
          ) : (
            <div>
              <div className="attendance-status" style={{ backgroundColor: 'red', width: '50%' }}>Wrong Location</div>
              <p style={{ marginTop: '10px', fontWeight: 'bold' }}>Remarks:</p>
              <textarea
                name="remarksLocation"
                placeholder="Write your remarks here"
                type="text"
                className="leave-input"
                htmlFor="remarksLocation"
                value={formData.remarksLocation}
                onChange={handleInputChange}
                rows="4"
                style={{textAlign: 'center'}}
              />
            </div>
          )}
        </div>


        {type === "Clockin" ?
          (<div className="attendance-box">
            <div className="font-weight-bold mt-1">My Clock In Time:</div>
            <div>{timeNow}</div>
            {checkinStatus === true ? (
              <div className="attendance-status">On Time</div>
            ) : (
              <div>
                <div className="attendance-status" style={{ backgroundColor: 'red', width: '50%' }}>Late</div>
                <p style={{ marginTop: '10px', fontWeight: 'bold' }}>Remarks:</p>
                <textarea
                  name="remarksTime"
                  placeholder="Write your remarks here"
                  type="text"
                  className="leave-input"
                  htmlFor="remarksTime"
                  value={formData.remarksTime}
                  onChange={handleInputChange}
                  rows="4"
                  style={{textAlign: 'center'}}
                />
              </div>
            )}
          </div>) : type === "Checkout" ?
            (<div className="attendance-box">
              <div className="font-weight-bold mt-1">My Clock Out Time:</div>
              <div>{timeNow}</div>
              {checkoutStatus === true ? (
                <div className="attendance-status">On Time</div>
              ) : (
                <div>
                  <div className="attendance-status" style={{ backgroundColor: 'red', width: '50%' }}>Check Out Early</div>
                  <p style={{ marginTop: '10px' }}>Remarks</p>
                  <textarea
                    name="remarksTime"
                    placeholder="Write your remarks here"
                    type="text"
                    className="leave-input"
                    htmlFor="remarksTime"
                    value={formData.remarksTime}
                    onChange={handleInputChange}
                    rows="4"
                    style={{textAlign: 'center'}}
                  />
                </div>
              )}
            </div>)
            : null}
        {loading ?
          (<Spin />) :
          (<button onClick={handleSubmit}>Proceed</button>)}

        <Modal
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          style={{
            // backgroundColor: '#f7f7f7',
            borderRadius: '20px'
          }}
          width={350}
        >
          <div className='text-center'>
            <img style={{ width: "20%" }} src={modalTic} alt="Tick" />
            <p className='font-weight-bold mt-2' style={{ fontSize: '15px' }}>Success!</p>
            <p style={{ fontSize: '19px' }}>Attendance has been successfully recorded.</p>
            <button onClick={handleOk}>Done</button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Attendance;
