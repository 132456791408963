import React from 'react'
import userImage from '../assets/userImage.png';
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { saveUser } from '../redux/slices/userSlice';
import axios from 'axios';
import { Modal } from 'antd'
import QRCode from 'qrcode.react'; // Import the QRCode component

function Header() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = localStorage.getItem('haleon-token');

        if (!token) {
          navigate("/login");
        }

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/checkToken`, { token });
        console.log(response.data.data);

        dispatch(saveUser(response.data.data));

      } catch (error) {
        console.error(error);
      }
    };

    checkToken();
  }, []);
  const userName = useSelector((state) => state.user.user?.userData.name);
  const promoterQR = useSelector((state) => state.user.user?.userData.promoterCode);
  const userLoc = useSelector((state) => state.user.user?.userData.schedule[0]?.location.location);
  const check_in = useSelector((state) => state.user.user?.userData.schedule[0]?.check_in);
  const checkout = useSelector((state) => state.user.user?.userData.schedule[0]?.checkout);
  const profilePicture = useSelector((state) => state.user.user?.signedUrl);



  const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(timeString).toLocaleTimeString([], options);
  };


  const checkintime = formatTime(check_in);
  const checkouttime = formatTime(checkout);

  const [isModalRoadshowOpen, setIsModalRoadshowOpen] = useState(false);

  const showModalRoadshow = () => {
    setIsModalRoadshowOpen(true);
  };

  const handleOkRoadshow = () => {
    setIsModalRoadshowOpen(false);
    navigate("/");
  };

  const handleCancelRoadshow = () => {
    setIsModalRoadshowOpen(false);
  };


  return (
    <div>
      <div className="header-top">
        <div className="d-flex align-items-center" style={{ gap: '10px' }}>
          <div>
            <div className="font-weight-bold" style={{ fontSize: '18px' }}>Hi {userName} !</div>
            <p className="font-weight-300" style={{ fontSize: '13px', margin: 0, fontWeight: '600' }} >My Location: {userLoc ? userLoc : "Not Available"}</p>
            <p style={{ fontSize: '13px', margin: 0 }} >My Duty Time:  {checkintime ? checkintime : "Not Available"} - {checkouttime ? checkouttime : null} </p>
          </div>
          <div style={{ margin: 'auto' }}>
            <img style={{ height: '80px', borderRadius: '500px', width: '80px' }} src={profilePicture} onClick={() => showModalRoadshow()} />
          </div>
        </div>
      </div>

      <Modal
        open={isModalRoadshowOpen}
        onOk={handleOkRoadshow}
        onCancel={handleCancelRoadshow}
        footer={null}
        style={{
          // backgroundColor: '#f7f7f7',
          borderRadius: '20px'
        }}
        width={350}
      >
        <div className='text-center'>
          <p className="Header text-center">QR Code</p>
          {/* <img src={promoterQR} /> */}
          {process.env.REACT_APP_API_ENV === "production" ?
          (<QRCode value={`https://my.haleonrewards.com/welcome?promoterCode=${promoterQR}`} size={200} />) :
          (<QRCode value={`https://my.haleon-rewards.d-rive.net/welcome?promoterCode=${promoterQR}`} size={200} />)
          }
        </div>
      </Modal>

    </div>
  )
}

export default Header
