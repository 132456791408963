import React, { useEffect, useState } from "react";
import ThirdHeader from "../components/ThirdHeader";
import { useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import homeIcon from "../assets/svgs/home.svg";
import inventoryIcon from "../assets/svgs/box.svg";
import scheduleIcon from "../assets/svgs/calendar.svg";
import leaveIcon from "../assets/svgs/leave.svg";
import historyIcon from "../assets/svgs/history.svg";

function Schedule() {
	const [scheduleData, setScheduleData] = useState([]);
	const promoterId = useSelector(state => state.user.user?.userData.id);
	const navigate = useNavigate();

	if (!promoterId) {
		navigate("/");
	}

	useEffect(() => {
		async function fetchSchedule() {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_URL}/api/getSchedule`,
					{
						promoterId: promoterId
					},
					{
						headers: {
							"Content-Type": "application/json"
						}
					}
				);

				if (response.status !== 200) {
					throw new Error("Failed to fetch schedule");
				}

				const schedule = response.data;
				setScheduleData(schedule);
			} catch (error) {
				console.error(error);
			}
		}

		fetchSchedule();
	}, [promoterId]);

	const formatTime = timeString => {
		const options = { hour: "numeric", minute: "numeric", hour12: true };
		return new Date(timeString).toLocaleTimeString([], options);
	};

	return (
		<div className="User-CSS">
			<ThirdHeader text="Schedule" />
			<div className="schedule-container">
				<div className="schedule-header">
					<div className="d-flex justify-content-between">
						<div className="schedule-column">Date</div>
						<div className="schedule-column">Venue</div>
						<div className="schedule-column">Time</div>
					</div>
				</div>
				<div className="schedule-body">
					{scheduleData.map(entry => (
						<div key={entry.id} className="d-flex justify-content-between schedule-entry">
							<div className="schedule-column">{new Date(entry.date).toLocaleDateString()}</div>
							<div className="schedule-column">{entry.location.location || "-"}</div>
							<div className="schedule-column">
								{entry.check_in ? formatTime(entry.check_in) : "-"} -{" "}
								{entry.checkout ? formatTime(entry.checkout) : "-"}
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="navbar-container">
				<div style={{ display: "flex" }}>
					<div
						className="box-style-3"
						style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "space-around" }}
					>
						<NavLink to="/">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={homeIcon} alt="home" />
								<p>Home</p>
							</div>
						</NavLink>
						<NavLink to="/inventory">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={inventoryIcon} alt="home" />
								<p>Inventory</p>
							</div>
						</NavLink>
						<NavLink to="/schedule" className={({ isActive }) => (isActive ? "navlink-active" : "")}>
							<div style={{ padding: "5px", width: "100%" }}>
								{/* <img style={{ width: '20px', height: '20px' }} src={scheduleIcon} alt='home' /> */}
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g id="clarity:calendar-line" clip-path="url(#clip0_506_6612)">
										<path
											id="Vector"
											d="M21.1041 4.33301H19.0278V5.61079H20.9444V19.6663H3.05553V5.61079H4.9722V4.33301H2.89581C2.74646 4.3355 2.59907 4.3674 2.46204 4.42687C2.32502 4.48633 2.20106 4.57221 2.09724 4.6796C1.99341 4.78698 1.91176 4.91377 1.85694 5.05272C1.80212 5.19166 1.77521 5.34005 1.77775 5.4894V19.7877C1.77521 19.9371 1.80212 20.0855 1.85694 20.2244C1.91176 20.3634 1.99341 20.4901 2.09724 20.5975C2.20106 20.7049 2.32502 20.7908 2.46204 20.8503C2.59907 20.9097 2.74646 20.9416 2.89581 20.9441H21.1041C21.2535 20.9416 21.4009 20.9097 21.5379 20.8503C21.6749 20.7908 21.7989 20.7049 21.9027 20.5975C22.0065 20.4901 22.0882 20.3634 22.143 20.2244C22.1978 20.0855 22.2247 19.9371 22.2222 19.7877V5.4894C22.2247 5.34005 22.1978 5.19166 22.143 5.05272C22.0882 4.91377 22.0065 4.78698 21.9027 4.6796C21.7989 4.57221 21.6749 4.48633 21.5379 4.42687C21.4009 4.3674 21.2535 4.3355 21.1041 4.33301Z"
											fill="#828C96"
										/>
										<path id="Vector_2" d="M5.61108 9.44434H6.88886V10.7221H5.61108V9.44434Z" fill="#828C96" />
										<path id="Vector_3" d="M9.44434 9.44434H10.7221V10.7221H9.44434V9.44434Z" fill="#828C96" />
										<path id="Vector_4" d="M13.2778 9.44434H14.5556V10.7221H13.2778V9.44434Z" fill="#828C96" />
										<path id="Vector_5" d="M17.1111 9.44434H18.3889V10.7221H17.1111V9.44434Z" fill="#828C96" />
										<path id="Vector_6" d="M5.61108 12.6387H6.88886V13.9164H5.61108V12.6387Z" fill="#828C96" />
										<path id="Vector_7" d="M9.44434 12.6387H10.7221V13.9164H9.44434V12.6387Z" fill="#828C96" />
										<path id="Vector_8" d="M13.2778 12.6387H14.5556V13.9164H13.2778V12.6387Z" fill="#828C96" />
										<path id="Vector_9" d="M17.1111 12.6387H18.3889V13.9164H17.1111V12.6387Z" fill="#828C96" />
										<path id="Vector_10" d="M5.61108 15.833H6.88886V17.1108H5.61108V15.833Z" fill="#828C96" />
										<path id="Vector_11" d="M9.44434 15.833H10.7221V17.1108H9.44434V15.833Z" fill="#828C96" />
										<path id="Vector_12" d="M13.2778 15.833H14.5556V17.1108H13.2778V15.833Z" fill="#828C96" />
										<path id="Vector_13" d="M17.1111 15.833H18.3889V17.1108H17.1111V15.833Z" fill="#828C96" />
										<path
											id="Vector_14"
											d="M6.88889 6.88845C7.05833 6.88845 7.22084 6.82114 7.34065 6.70133C7.46047 6.58151 7.52778 6.41901 7.52778 6.24957V2.41623C7.52778 2.24679 7.46047 2.08428 7.34065 1.96447C7.22084 1.84466 7.05833 1.77734 6.88889 1.77734C6.71945 1.77734 6.55694 1.84466 6.43713 1.96447C6.31731 2.08428 6.25 2.24679 6.25 2.41623V6.24957C6.25 6.41901 6.31731 6.58151 6.43713 6.70133C6.55694 6.82114 6.71945 6.88845 6.88889 6.88845Z"
											fill="#828C96"
										/>
										<path
											id="Vector_15"
											d="M17.1111 6.88845C17.2805 6.88845 17.443 6.82114 17.5628 6.70133C17.6826 6.58151 17.7499 6.41901 17.7499 6.24957V2.41623C17.7499 2.24679 17.6826 2.08428 17.5628 1.96447C17.443 1.84466 17.2805 1.77734 17.1111 1.77734C16.9416 1.77734 16.7791 1.84466 16.6593 1.96447C16.5395 2.08428 16.4722 2.24679 16.4722 2.41623V6.24957C16.4722 6.41901 16.5395 6.58151 16.6593 6.70133C16.7791 6.82114 16.9416 6.88845 17.1111 6.88845Z"
											fill="#828C96"
										/>
										<path id="Vector_16" d="M8.80566 4.33301H15.1946V5.61079H8.80566V4.33301Z" fill="#828C96" />
									</g>
									<defs>
										<clipPath id="clip0_506_6612">
											<rect width="23" height="23" fill="white" transform="translate(0.5 0.5)" />
										</clipPath>
									</defs>
								</svg>

								<p>Schedule</p>
							</div>
						</NavLink>
						<NavLink to="/leave">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={leaveIcon} alt="home" />
								<p>Leave</p>
							</div>
						</NavLink>
						<NavLink to="/history">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={historyIcon} alt="home" />
								<p>History</p>
							</div>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Schedule;
