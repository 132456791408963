import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import Sidebar from '../../components/Sidebar';

const PosmReport = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/posmReport`)
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const columns = [
        {
            title: 'Promoter Name',
            dataIndex: 'promoterName',
            key: 'promoterName',
        },
        {
            title: 'Promoter Code',
            dataIndex: 'promoterCode',
            key: 'promoterCode',
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Brand Name',
            dataIndex: 'brandName',
            key: 'brandName',
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            render: (sku) => (
                <ul>
                    {sku.map((entry, index) => (
                        <li key={index}>
                            {entry.sku}: {entry.quantity}
                        </li>
                    ))}
                </ul>
            ),
        },
        {
            title: 'Image 1',
            dataIndex: 'image1',
            key: 'image1',
            render: (image) => <img src={image} alt="Image 1" style={{ maxWidth: '50px' }} />,
        },
        {
            title: 'Image 2',
            dataIndex: 'image2',
            key: 'image2',
            render: (image) => <img src={image} alt="Image 2" style={{ maxWidth: '50px' }} />,
        },
    ];


    return (
        <div>
            <Sidebar />
            <div className="content">
                <Table dataSource={data} columns={columns} />
            </div>
        </div>
    );
};

export default PosmReport;
