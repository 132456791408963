import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import useWindowDimensions from "../function/useWindowDimensions";
import helpline from "../assets/draggable.png";

const WhatsAppHotline = () => {
	const dragStartPositionXYRef = useRef();
	const { height, width } = useWindowDimensions();
	const [imgPosition, setPosition] = useState({ x: 0, y: 0 });
	return (
		<div>
			<Draggable
				// defaultPosition={{
				// 	x: window.innerWidth - 13,
				// 	y: -window.innerHeight / 1.5
				// }}
				defaultPosition={{ x: 0, y: 0 }}
				style={{ position: "relative", zIndex: "2" }}
			>
				<div style={{ position: "relative", zIndex: "2" }}>
					<a
						href="https://wa.me/+601154125382"
						onTouchStart={e =>
							setPosition({ x: e.target.getBoundingClientRect().x, y: e.target.getBoundingClientRect().y })
						}
						onTouchEnd={e => {
							const newX = e.target.getBoundingClientRect().x;
							const newY = e.target.getBoundingClientRect().y;
							if (imgPosition.x === newX && imgPosition.y === newY) e.target.click();
							else setPosition({ x: newX, y: newY });
						}}
					>
						<img
							style={{ width: "20%", position: "absolute", marginLeft: "70%", marginTop: "35%" }}
							src={helpline}
							alt="whatsapp"
						/>
					</a>
				</div>
			</Draggable>
		</div>
	);
};

export default WhatsAppHotline;
