import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'antd';
import Sidebar from '../../components/Sidebar';
import { useNavigate } from 'react-router-dom';

const LeaveApplication = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getPromoter`)
            .then(response => {
                setData(response.data.promoter);
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const columns = [
        { title: 'name', dataIndex: 'name', key: 'name' },
        { 
            title: 'Profile Picture', 
            dataIndex: 'image_url', 
            key: 'image_url',
            render: (image_url) => <img src={image_url} alt="Profile" style={{ width: '50px', height: '50px' }} />,
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => (
                <Button onClick={() => handleActionClick(id)}>Update</Button>
            ),
        },
    ];



    const handleActionClick = (id) => {
        navigate(`/admin/editPromoter`, { state: { id } });
    };


    return (
        <div>
            <Sidebar />
            <div className="content">
                <Table dataSource={data} columns={columns} />
            </div>
        </div>
    );
};

export default LeaveApplication;
