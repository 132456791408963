import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { saveTokenAdmin, saveAdmin } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux"

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        if (e.target.name === "username") {
            setUsername(e.target.value);
        } else if (e.target.name === "password") {
            setPassword(e.target.value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !password) {
            setError("Ehhh");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/adminLogin`, { username, password });
            const { token } = response.data;
            localStorage.setItem("admin-token", token);
            dispatch(saveTokenAdmin({ token: response.data.token }));
            navigate("/admin/summaryPerformance");
        } catch (error) {
            if (error.response) {
                setError(error.response.data.error);
            } else {
                setError("Internal Error");
            }
        }
    };


    return (
        <div className="admin-portal">
            <form
                style={{ textAlign: "center", marginTop: "10%" }}
                onSubmit={handleSubmit}
            >
                {error && <p className="error">{error}</p>}
                <div className="form-group">
                    <label
                        style={{ color: "#169ad7", textAlign: "left" }}
                        htmlFor="username"
                    >
                        ID:
                    </label>
                    <br></br>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={handleInputChange}
                        placeholder="Enter your ID"
                    />
                </div>
                <div className="form-group">
                    <label
                        style={{ color: "#169ad7", textAlign: "left" }}
                        htmlFor="password"
                    >
                        Password:
                    </label>
                    <br></br>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={handleInputChange}
                        placeholder="Enter your password"
                    />
                </div>
                <button style={{ borderRadius: "30px", width: "30%", marginTop: "20px" }} type="submit">
                    Login
                </button>
            </form>
        </div>
    );
};

export default Login;