import React, { useState } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Modal, message, Spin } from 'antd';
import arrowUp from "../../assets/arrowUp.png";
import arrowDown from "../../assets/arrowDown.png";
import { useLocation, useNavigate } from "react-router-dom";

function CustomerReached() {

  const location = useLocation();
  const navigate = useNavigate();
  const [enggagedCustomer, setEnggagedCustomer] = useState(1);
  const [purchasedCustomer, setPurchasedCustomer] = useState(1);
  const [registeredCompanyUsersLength, setRegisteredCompanyUsersLength] = useState(0);
  const promoterId = location.state.promoterId;
  const promoterCode = location.state.promoterCode;
  // const yesterdayDate = new Date(location.state.blockedDate);
  const yesterdayDate = location.state.yesterdayDate;
  console.log(location.state);

  const fetchData = async (promoterCode) => {

    var myHeaders = new Headers();
    myHeaders.append("x-company-id", process.env.REACT_APP_COMPANY_ID);
    myHeaders.append("x-api-key", process.env.REACT_APP_API_KEY);
    myHeaders.append("x-api-secret", process.env.REACT_APP_API_SECRET);
    myHeaders.append("x-version", '~2');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DRIVE_URL}/api/promoter/customers?promoterCode=${promoterCode}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result.registeredCompanyUsers);
        setRegisteredCompanyUsersLength(result.registeredCompanyUsers ? result.registeredCompanyUsers.length : 0);
      })
      .catch(error => console.log('error', error));
  };

  fetchData(promoterCode);

  const handleArrowUp = (inputNumber) => {
    if (inputNumber === 1) {
      setEnggagedCustomer((prevValue) => prevValue + 1);
    }
  };

  const handleArrowDown = (inputNumber) => {
    if (inputNumber === 1) {
      setEnggagedCustomer((prevValue) => Math.max(1, prevValue - 1));
    }
  };

  const handleArrowUp2 = (inputNumber) => {
    if (inputNumber === 1) {
      setPurchasedCustomer((prevValue) => prevValue + 1);
    }
  };

  const handleArrowDown2 = (inputNumber) => {
    if (inputNumber === 1) {
      setPurchasedCustomer((prevValue) => Math.max(1, prevValue - 1));
    }
  };

  const handleEnggagedCustomer = async (event) => {
    event.preventDefault();

    try {
      //   setCustomerLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateExistingCustomer`, { promoterId, enggagedCustomer, registeredCompanyUsersLength, purchasedCustomer, yesterdayDate }, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response) {
        navigate("/")
      }
      //   console.log("Roadshow sales submitted:", response.data);
      //   setTotalCustomer(response.data.existing)
      //   handleOkCustomerOpen();
      //   setCustomerLoading(false);
    } catch (error) {
      console.log("error")
      // console.error("Error submitting roadshow sales:", error.response.data);
      //   setCustomerLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <div className='text-center' style={{ paddingLeft: '25px', paddingRight: '25px', marginTop: '50px' }}>
        {/* <p className="Header text-center">Existing Customer</p> */}
        <p style={{ fontSize: '20px', textAlign: 'center' }}>How many customer(s) you have engaged today?</p>
        <form onSubmit={handleEnggagedCustomer}>
          <div className="d-flex justify-content-center align-items-center position-relative">
            <div style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)', display: "grid", gap: '10px' }}>
              <img onClick={() => handleArrowUp(1)} src={arrowUp} />
              <img onClick={() => handleArrowDown(1)} src={arrowDown} />
            </div>
            <div style={{ position: 'relative' }}>
              <input style={{ height: '100px', width: '100px', fontSize: '30px', textAlign: 'center' }} value={enggagedCustomer} readOnly />
            </div>
          </div>

          {/* <p className="Header text-center mt-2">New Customer</p> */}
          <p style={{ fontSize: '20px', textAlign: 'center', marginTop: '40px' }}>How many of them purchased from you today?</p>
          <div className="d-flex justify-content-center align-items-center position-relative">
            <div style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)', display: "grid", gap: '10px' }}>
              <img onClick={() => handleArrowUp2(1)} src={arrowUp} />
              <img onClick={() => handleArrowDown2(1)} src={arrowDown} />
            </div>
            <div style={{ position: 'relative' }}>
              <input style={{ height: '100px', width: '100px', fontSize: '30px', textAlign: 'center' }} value={purchasedCustomer} readOnly />
            </div>
          </div>

          {/* <button style={{ marginTop: '20px' }}>Save</button> */}
          <button
            style={{ backgroundColor: "#30E700", color: "black", marginTop: '20px' }}
          >
            Save Record
          </button>
        </form>
        <button
          style={{ backgroundColor: "black", color: "white", marginTop: '20px' }}
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default CustomerReached;
