import uploadImage from "../assets/uploadMedical.png";
import sample from "../assets/photoSample.png";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { Modal } from "antd";
import SecondHeader from "../components/SecondHeader";
import remove from "../assets/remove.png.png"

function Homeshelf() {
  const location = useLocation();
  const [formData, setFormData] = useState({
    homeshelfImage: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(location.state);
  const yesterdayDate = location.state?.yesterdayDate;

  console.log(yesterdayDate);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const handleImageClick = () => {
    document.getElementById("certificateImageInput").click();
  };

  const handleInputChange = (event) => {
    const { name, type } = event.target;

    if (type === "file") {
      const selectedFile = event.target.files[0];

      // Check if the limit of 2 images is reached
      if (formData.homeshelfImage.length < 2) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          homeshelfImage: [...prevFormData.homeshelfImage, selectedFile],
        }));
      } else {
        console.log("Maximum limit of 2 images reached");
      }
    }
  };

  const removeImage = (index) => {
    setFormData((prevFormData) => {
      const updatedImages = [...prevFormData.homeshelfImage];
      updatedImages.splice(index, 1);
      return {
        ...prevFormData,
        homeshelfImage: updatedImages,
      };
    });
  };

  const handleNextClick = () => {
    navigate("/record", { state: { homeshelfImage: formData.homeshelfImage, brandId: location.state.brandId, userBrandId: location.state.userBrandId, yesterdayDate: yesterdayDate } });
  };

  return (
    <div className='User-CSS'>
      <SecondHeader text="Homeshelf Display" />
      <div className="homeshelf-container">
        <p className="Header">Homeshelf Display</p>

        <div
          className="box-style-3 text-center"
          style={{ padding: "15%" }}
          onClick={handleImageClick}
        >
          <input
            type="file"
            name="certificateImage"
            id="certificateImageInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleInputChange}
          />
          <img src={uploadImage} alt="Upload Placeholder" />
          <p className="text-center">Upload a photo of the homeshelf.</p>
        </div>

        {formData.homeshelfImage.map((image, index) => (

          <div className="box-style-3">
            <div key={index} className="uploaded-image" style={{ display: 'flex', marginTop: '20px' }}>
              <img
                src={URL.createObjectURL(image)}
                alt={`Uploaded ${index + 1}`}
                style={{ maxWidth: "20%" }}
              />
              <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{image.name}</span>
                <span style={{ fontSize: '12px' }}>{(image.size / 1024).toFixed(2)} KB</span>
              </div>
              <div onClick={() => removeImage(index)} style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                <img src={remove} alt="Remove" />
              </div>
            </div>
          </div>
        ))}



        {formData.homeshelfImage ?
          (<div>
            <p className="Header text-center">Photo Sample</p>
            <div className="box-style-3 text-center" onClick={showModal}>
              <div className="d-flex" style={{ gap: "25px" }}>
                <img style={{ width: "25%", height: "100%" }} src={sample} alt="Sample" />
                <p className="text-left">
                  Please ensure your photo clearly captures the product and POSM in the same frame.
                </p>
              </div>
            </div></div>) : null}

        <button onClick={formData.homeshelfImage.length > 0 ? handleNextClick : null}>Next</button>

        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          style={{
            // backgroundColor: '#f7f7f7',
            borderRadius: '20px'
          }}
          width={350}
        >
          <div className="text-center">
            <img src={sample} alt="Sample" style={{ width: '100%', marginTop: '20px' }} />
            <p className="Header" style={{ textAlign: 'center', marginTop: '10px' }}>Photo Sample</p>
            <p>Please ensure your photo clearly captures the product and POSM in the same frame.</p>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Homeshelf;
