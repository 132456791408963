import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import Sidebar from '../../components/Sidebar';

const SummaryPerformance = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/summaryPerformance`)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []); 

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Promoter Code', dataIndex: 'promoterCode', key: 'promoterCode' },
    { title: 'Total Schedule', dataIndex: 'totalSchedule', key: 'totalSchedule' },
    { title: 'Total Attendance', dataIndex: 'totalAttendance', key: 'totalAttendance' },
    { title: 'Total Sales', dataIndex: 'totalSales', key: 'totalSales' },
    { title: 'Total Amount Roadshow', dataIndex: 'totalAmountRoadshow', key: 'totalAmountRoadshow' },
    { title: 'Total Bone', dataIndex: 'totalBone', key: 'totalBone' },
    { title: 'Total Quantity', dataIndex: 'totalQuantity', key: 'totalQuantity' },
    { title: 'Total Absent', dataIndex: 'totalAbsent', key: 'totalAbsent' },
    { title: 'Total Late Checkin', dataIndex: 'totalLateCheckin', key: 'totalLateCheckin' },
    { title: 'Total Early Checkout', dataIndex: 'totalEarlyCheckout', key: 'totalEarlyCheckout' },
  ];

  return (
    <div>
      <Sidebar />
      <div className="content">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default SummaryPerformance;
