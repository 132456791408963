import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'antd';
import Sidebar from '../../components/Sidebar';
import { useNavigate } from 'react-router-dom';

const PremiumRestock = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/restockPremium`)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    // { title: 'Promoter Code', dataIndex: 'promoterCode', key: 'promoterCode' },
    { title: 'Premium 1', dataIndex: 'Premium1', key: 'Premium1' },
    { title: 'Premium 2', dataIndex: 'Premium2', key: 'Premium2' },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (id) => (
        <Button onClick={() => handleActionClick(id)}>Update</Button>
      ),
    },
  ];


  const handleActionClick = (id) => {
    navigate(`/admin/editRestockPremium`, { state: { id } });
  };

  return (
    <div>
      <Sidebar />
      <div className="content">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default PremiumRestock;
