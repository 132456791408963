import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, Select, message } from 'antd';
import Sidebar from '../../components/Sidebar';

const { Option } = Select;

const EditPremium = () => {
    const location = useLocation();
    const userId = location.state.id;

    const [premiumItems, setPremiumItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [dynamicFields, setDynamicFields] = useState([{ premiumName: '', quantity: '' }]);

    useEffect(() => {
        // Fetch premium items from your API
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getRestockPremium`)
            .then(response => {
                setPremiumItems(response.data.data.map(item => item.name));
            })
            .catch(error => {
                console.error(error);
                message.error('Failed to fetch premium items');
            });
    }, []);

    const onFinish = () => {
        const dataToSend = selectedItems.map(item => ({
            id: item.id,  // Assuming your backend expects an 'id' property for premium items
            quantity: item.quantity,
        }));

        console.log(dataToSend);
    
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/editPremiumStock`, { userId, data: dataToSend })
            .then(response => {
                console.log(response);
                message.success('Premium values updated successfully');
            })
            .catch(error => {
                console.error(error);
                message.error('Failed to update premium values');
            });
    };    

    const handleItemChange = (index, field, value) => {
        const updatedFields = [...dynamicFields];
        updatedFields[index][field] = value;
        setDynamicFields(updatedFields);
    
        const updatedItems = updatedFields.map(field => ({
            id: field.premiumName,  // Assuming your backend expects an 'id' property for premium items
            quantity: field.quantity,
        }));
        setSelectedItems(updatedItems);
    };
    

    const addPremiumItem = () => {
        setDynamicFields([...dynamicFields, { premiumName: '', quantity: '' }]);
    };

    return (
        <div>
            <Sidebar />
            <div className="content">
                <Form
                    name="editPremiumForm"
                    onFinish={onFinish}
                >
                    {dynamicFields.map((field, index) => (
                        <div key={index} style={{ marginBottom: '16px' }}>
                            <Form.Item label={`Premium ${index + 1}`}>
                                <Select
                                    placeholder={`Select premium item ${index + 1}`}
                                    value={field.premiumName}
                                    onChange={(value) => handleItemChange(index, 'premiumName', value)}
                                >
                                    {premiumItems.map((item, itemIndex) => (
                                        <Option key={itemIndex} value={item}>{item}</Option>
                                    ))}
                                </Select>
                                <Input
                                    placeholder="Enter quantity"
                                    value={field.quantity}
                                    onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                />
                            </Form.Item>
                        </div>
                    ))}

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save Changes
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="dashed" onClick={addPremiumItem} style={{ width: '100%' }}>
                            Add Premium Item
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default EditPremium;