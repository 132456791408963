import { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { saveToken, saveUser, saveLocation } from "../../redux/slices/userSlice";
import goNow from "../../assets/goNow.png";
import { Modal, message, Spin } from "antd";

function ReuploadHomeshelf() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const promoterId = location.state.promoterId;
	const [completeBrand, setCompleteBrand] = useState([]);
	const [totalHomeshelf, setTotalHomeshelf] = useState(null);
	const [brand, setBrand] = useState([]);
	const [totalBrandComplete, setTotalBrandComplete] = useState(null);
	const [isAuth, setIsAuth] = useState(true);

	const yesterdayDate =
		location.state.yesterdayDate && location.state.yesterdayDate.split("/").length === 3
			? `${location.state.yesterdayDate.split("/")[2]}-${location.state.yesterdayDate.split("/")[1]}-${
					location.state.yesterdayDate.split("/")[0]
				}`
			: "";

	console.log(yesterdayDate);

	const handleIgnore = async () => {
		try {
			// setBoneLoading(true);
			const postData = {
				promoterId: promoterId,
				selectedIcon: "homepage"
			};

			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/ignoreTask`, postData);

			console.log(response);
			if (response) {
				navigate("/");
			}
		} catch (error) {
			console.error("Unexpected error:", error);
			message.error(error.response?.data.error || "An error occurred");
		}
	};

	useEffect(() => {
		const checkToken = async () => {
			try {
				const token = localStorage.getItem("haleon-token");

				if (!token) {
					navigate("/login");
				}

				const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/checkYesterday`, {
					token,
					yesterdayDate
				});
				console.log(response);
				dispatch(saveUser(response.data.data));
				setCompleteBrand(response.data.data.findBrandComplete);
				setTotalBrandComplete(response.data.data.totalBrandComplete);
				setIsAuth(true);
			} catch (error) {
				console.error(error);
				setIsAuth(false);
			}
		};

		checkToken();
		if (!isAuth) {
			navigate("/login");
		}
	}, [isAuth]);

	const getBrand = async () => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getBrand`, { promoterId });
			// console.log(response.data)
			setBrand(response.data.getBrand);
			setTotalHomeshelf(response.data.brandNo);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getBrand();
	}, []);
	console.log(brand);
	return (
		<div>
			<Header />
			<div className="text-center" style={{ paddingLeft: "25px", paddingRight: "25px" }}>
				<div>
					<div style={{ display: "flex", justifyContent: "space-around" }}>
						<p className="Header mt-5">Homeshelf Display</p>
						<p className="Header mt-5">
							{totalBrandComplete ? totalBrandComplete : "-"}/{totalHomeshelf}
						</p>
					</div>
					{brand.map(brand => (
						<div key={brand.id} className="box-style-3">
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="mt-2">
								<div className="brand-text text-left">
									<img src={brand.image_url} alt={brand.name} style={{ width: "50px" }} />
									{"      "}
									{brand.name}
								</div>
								{completeBrand.some(completeBrand => completeBrand.id === brand.id) ? (
									<div className="brand-status text-right">Complete</div>
								) : (
									<img
										src={goNow}
										onClick={() =>
											navigate(`/homeshelf`, {
												state: {
													brandId: brand.id,
													userBrandId: brand.promoterBrand[0].id,
													yesterdayDate: yesterdayDate
												}
											})
										}
									/>
								)}
							</div>
						</div>
					))}
					{totalBrandComplete > 0 ? (
						<button
							style={{ backgroundColor: "#30E700", color: "black", marginTop: "20px" }}
							onClick={() => handleIgnore()}
						>
							Save Record
						</button>
					) : (
						<button
							style={{ backgroundColor: "#AAB4B9", color: "white", marginTop: "20px" }}
							//   onClick={() => handleIgnore()}
						>
							Save Record
						</button>
					)}
					<button style={{ backgroundColor: "black", color: "white", marginTop: "20px" }} onClick={() => navigate(-1)}>
						Back
					</button>
				</div>
			</div>
		</div>
	);
}

export default ReuploadHomeshelf;
