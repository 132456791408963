import React, { useState } from "react";
import { Modal, message } from 'antd';
import Header from "../components/Header";
import block from "../assets/blockedImage.png";
import homeshelfIcon from "../assets/homeshelfIcon.png";
import salesIcon from "../assets/salesIcon.png";
import customerIcon from "../assets/customerIcon.png";
import bonecheckIcon from "../assets/bonecheckIcon.png";
import homeshelfBlinkIcon from "../assets/homeshelfBlinkIcon.png";
import salesBlinkIcon from "../assets/salesBlinkIcon.png";
import customerBlinkIcon from "../assets/customerBlinkIcon.png";
import bonecheckBlinkIcon from "../assets/bonecheckBlinkIcon.png";
import homeshelfFalseIcon from "../assets/homeshelfFalseIcon.png";
import salesFalseIcon from "../assets/salesFalseIcon.png";
import customerFalseIcon from "../assets/customerFalseIcon.png";
import bonecheckFalseIcon from "../assets/bonecheckFalseIcon.png";
import warning from "../assets/noto_warning.png"
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

function Blocked() {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [reuploadChecked, setReuploadChecked] = useState(false);
    const [ignoreChecked, setIgnoreChecked] = useState(false);
    const promoterId = useSelector((state) => state.user.user?.userData.id);
    const promoterCode = useSelector((state) => state.user.user?.userData.promoterCode);

    const [isModalRoadshowOpen, setIsModalRoadshowOpen] = useState(false);

    const showModalRoadshow = () => {
        setIsModalRoadshowOpen(true);
    };

    const handleOkRoadshow = () => {
        setIsModalRoadshowOpen(false);
        navigate("/");
    };

    const handleCancelRoadshow = () => {
        setIsModalRoadshowOpen(false);
    };



    const getYesterdayDate = () => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return yesterday.toLocaleDateString();
    };

    const yesterdayDate = new Date(location.state.data.blockedDate).toLocaleDateString();

    const handleIconClick = (icon) => {
        setSelectedIcon(icon);
    };

    const handleProceed = () => {
        if (selectedIcon === null) {
            alert("Please select an option before proceeding.");
            return;
        }

        if (reuploadChecked) {
            if (selectedIcon === "homepage") {
                navigate("/reuploadHomeshelf", { state: { promoterId, yesterdayDate } })
            } else if (selectedIcon === "bonecheck") {
                navigate("/boneCheck", { state: { promoterId, yesterdayDate } })
            } else if (selectedIcon === "sales") {
                navigate("/upload", { state: { promoterId, yesterdayDate } })
            } else {
                navigate("/customerReached", { state: { promoterId, yesterdayDate, promoterCode } })
            }
        } else {
            showModalRoadshow(selectedIcon)
        }
    };

    const handleReuploadRadioChange = (event) => {
        setReuploadChecked(event.target.checked);
    };

    const handleIgnoreRadioChange = (event) => {
        setIgnoreChecked(event.target.checked);
    };

    const reuploadText = `Reupload ${selectedIcon ? selectedIcon : "text"} on ${yesterdayDate}`;
    const ignoreText = `I did not make any ${selectedIcon ? selectedIcon : "text"} on ${yesterdayDate}`;

    // const yesterdayDate = getYesterdayDate();

    const handleIgnore = async () => {
        try {
            // setBoneLoading(true);
            const postData = {
                promoterId: promoterId,
                date: yesterdayDate,
            };

            if (selectedIcon) {
                postData.selectedIcon = selectedIcon;
            }

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/ignoreTask`, postData);

            console.log(response);
            if (response) {
                navigate("/")
            }
        } catch (error) {

            console.error('Unexpected error:', error);
            message.error(error.response?.data.error || 'An error occurred');
        }
    };

    return (
        <div>
            <Header />
            <div className="block-container text-center">
                <img style={{ width: "80px", height: "100px" }} src={block} />
                <p className="Header text-center">Access Blocked</p>
                <div className="text text-center mb-5" style={{fontSize: '15px'}}>
                Sorry, the access to the system is currently blocked as the below report submission for the previous duty day have not been recorded. Please ensure all the report are up to date to regain access. If you need assistance, please contact our support team at 012-345 6789.
                </div>

                <div>
                    {location.state.data.blockedHomeshelf === true ?
                        (<img
                            style={{ width: "50px", cursor: "pointer" }}
                            src={selectedIcon === "homepage" ? homeshelfBlinkIcon : homeshelfIcon}
                            onClick={() => handleIconClick("homepage")}
                        />) : (<img src={homeshelfFalseIcon} style={{ width: "50px", cursor: "pointer" }} />)
                    }
                    {location.state.data.blockedBoneCheck === true ?
                        (<img
                            style={{ width: "50px", cursor: "pointer" }}
                            src={selectedIcon === "bonecheck" ? bonecheckBlinkIcon : bonecheckIcon}
                            onClick={() => handleIconClick("bonecheck")}
                        />) : (<img src={bonecheckFalseIcon} style={{ width: "50px", cursor: "pointer" }} />)
                    }
                    {location.state.data.blockedSales === true ?
                        (<img
                            style={{ width: "50px", cursor: "pointer" }}
                            src={selectedIcon === "sales" ? salesBlinkIcon : salesIcon}
                            onClick={() => handleIconClick("sales")}
                        />) : (<img src={salesFalseIcon} style={{ width: "50px", cursor: "pointer" }} />)
                    }
                    {location.state.data.blockedCustomer === true ?
                        (<img
                            style={{ width: "50px", cursor: "pointer" }}
                            src={selectedIcon === "customer" ? customerBlinkIcon : customerIcon}
                            onClick={() => handleIconClick("customer")}
                        />) : (<img src={customerFalseIcon} style={{ width: "50px", cursor: "pointer" }} />)
                    }
                </div>

                {selectedIcon && (
                    <div>
                        {selectedIcon === "homepage" ?
                            (<h4 style={{marginTop: '10px', marginBottom: '10px'}}>Homeshelf Display</h4>) :
                            selectedIcon === "bonecheck" ?
                                (<h4 style={{marginTop: '10px', marginBottom: '10px'}}>Bone Check</h4>) :
                                selectedIcon === "sales" ?
                                    (<h4 style={{marginTop: '10px', marginBottom: '10px'}}>Record Sales</h4>) :
                                    selectedIcon === "customer" ?
                                        (<h4 style={{marginTop: '10px', marginBottom: '10px'}}>Customer Reached</h4>) :
                                        null
                        }
                        <input
                            type="radio"
                            id="reupload"
                            name="uploadOption"
                            value="reupload"
                            checked={reuploadChecked}
                            onChange={handleReuploadRadioChange}
                        />
                        <label style={{ fontSize: '12px' }}> {reuploadText}</label>
                        <br />
                        <input
                            type="radio"
                            id="ignore"
                            name="uploadOption"
                            value="ignore"
                            checked={ignoreChecked}
                            onChange={handleIgnoreRadioChange}
                        />
                        <label style={{ fontSize: '12px' }}> {ignoreText}</label>
                        <br />
                    </div>
                )}


                <button
                    style={{ backgroundColor: "#30E700", color: "black", marginTop: '20px' }}
                    onClick={handleProceed}
                >
                    Proceed
                </button>
            </div>

            <Modal
                open={isModalRoadshowOpen}
                onOk={handleOkRoadshow}
                onCancel={handleCancelRoadshow}
                footer={null}
                style={{
                    // backgroundColor: '#f7f7f7',
                    borderRadius: '20px'
                }}
                width={350}
            >
                <div className='text-center'>
                    <img src={warning} />

                    <p className="Header text-center mt-3">Confirmation</p>
                    <p>Please note that by choosing not to record, you acknowledge that your entitlement for that day will be forfeited.</p>
                    <button
                        onClick={handleIgnore}
                        style={{ backgroundColor: "#30E700", color: "black", marginTop: '20px' }}>
                        Confirm
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default Blocked;
