import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ThirdHeader from '../../components/ThirdHeader';
import { Spin, message } from 'antd';

function InventoryOnHand() {
    const promoterId = useSelector((state) => state.user.user?.userData.id);
    const navigate = useNavigate();
    const location = useLocation();

    if (!promoterId) {
        navigate("/");
    }

    const initialInventoryItems = location.state.filter(item => item.skuQuantity >= 0);
    const [inventoryItems, setInventoryItems] = useState(initialInventoryItems);

    const handleQuantityChange = (index, newValue) => {
        const updatedItems = [...inventoryItems]; // Create a copy of the array
        updatedItems[index].skuQuantity = newValue; // Update the quantity of the specific item
        setInventoryItems(updatedItems); // Update state with the modified array
    };

    const handleSave = async () => {
        try {
            const itemsToSave = inventoryItems.filter(item => item.skuQuantity >= 0);
           const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updatePremiumItem`, {
                premiumItems: itemsToSave,
                promoterId: promoterId
            });
              
            if (response) {
                message.success('Saved!');
                navigate("/")
            }
            // Optionally, you can handle success scenario here, e.g., show a success message
        } catch (error) {
            console.error("Failed to update inventory:", error);
            message.error(error.response?.data.error || 'An error occurred');
            // Optionally, you can handle error scenario here, e.g., show an error message
        }
    };

    return (
        <div className='User-CSS'>
            <ThirdHeader text="Inventory" />
            <div className="inventory-container" style={{ marginBottom: '100px' }}>
                <p className="Header">Initial Premium On Hand</p>
                {inventoryItems.map((item, index) => (
                    <div key={item.id} className="inventory-item">
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                            <p style={{ flex: 1, overflowWrap: 'break-word', wordWrap: 'break-word' }}>{item.premium.name}</p>
                            <input
                                className="leave-input"
                                type="text"
                                style={{ marginLeft: '10px', border: '1px solid black', width: '70px', textAlign: 'center' }}
                                placeholder="QTY"
                                value={item.skuQuantity}
                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                            />
                        </div>
                    </div>
                ))}
                <button
                    style={{ backgroundColor: "#30E700", color: "black", marginTop: '20px' }}
                    onClick={handleSave}
                >
                    Save
                </button>
            </div>
        </div>
    );
}

export default InventoryOnHand;
