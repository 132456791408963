import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import ThirdHeader from "../components/ThirdHeader";
import ontime from "../assets/ontime.png";
import clockoutearly from "../assets/clockoutearly.png";
import absent from "../assets/absent.png";
import onleave from "../assets/onleave.png";
import homeIcon from "../assets/svgs/home.svg";
import inventoryIcon from "../assets/svgs/box.svg";
import scheduleIcon from "../assets/svgs/calendar.svg";
import leaveIcon from "../assets/svgs/leave.svg";
import historyIcon from "../assets/svgs/history.svg";

function History() {
	const [leaveData, setLeaveData] = useState([]);
	const [attendanceData, setAttendanceData] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const navigate = useNavigate();
	const promoterId = useSelector(state => state.user.user?.userData.id);

	if (!promoterId) {
		navigate("/");
	}

	const getLeaveData = async promoterId => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getLeave`, { promoterId });

			if (response.status === 200) {
				setLeaveData(response.data);
			} else {
				console.error("Failed to fetch leave data");
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getAttendanceData = async promoterId => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getAttendance`, { promoterId });

			if (response.status === 200) {
				setAttendanceData(response.data);
			} else {
				console.error("Failed to fetch attendance data");
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getLeaveData(promoterId);
		getAttendanceData(promoterId);
	}, [promoterId]);

	const handleDateChange = date => {
		setSelectedDate(date);
	};

	const handleDateClick = () => {
		handleDateSelection(selectedDate);
	};

	const handleDateSelection = date => {
		const formattedDate = new Date(date).toLocaleDateString();
		const leaveEntry = leaveData.find(entry => {
			const entryFormattedDate = new Date(entry.date).toLocaleDateString();
			return entryFormattedDate === formattedDate;
		});

		const lateEntry = attendanceData.find(entry => {
			const entryFormattedDate = new Date(entry.date).toLocaleDateString();
			return (
				entryFormattedDate === formattedDate &&
				(entry.checkin_status !== "On Time" || entry.checkout_status !== "On Time")
			);
		});

		const ontimeEntry = attendanceData.find(entry => {
			const entryFormattedDate = new Date(entry.date).toLocaleDateString();
			return (
				entryFormattedDate === formattedDate &&
				entry.checkin_status === "On Time" &&
				entry.checkout_status === "On Time"
			);
		});

		const absentEntry = attendanceData.find(entry => {
			const entryFormattedDate = new Date(entry.date).toLocaleDateString();
			return entryFormattedDate === formattedDate && entry.absent === true;
		});

		if (leaveEntry) {
			return (
				<div
					onClick={() => navigate("/leaveStatus", { state: { leaveId: leaveEntry.id } })}
					style={{ marginTop: "-21px", border: "3px solid #00A2E7", borderRadius: "500px" }}
				>
					.
				</div>
			);
		} else if (absentEntry) {
			return (
				<div
					onClick={() => navigate("/summary", { state: { date: absentEntry.date } })}
					style={{ marginTop: "-21px", border: "3px solid #FF0D0D", borderRadius: "500px" }}
				>
					.
				</div>
			);
		} else if (lateEntry) {
			return (
				<div
					onClick={() => navigate("/summary", { state: { date: lateEntry.date } })}
					style={{ marginTop: "-21px", border: "3px solid #FF9E0D", borderRadius: "500px" }}
				>
					.
				</div>
			);
		} else if (ontimeEntry) {
			return (
				<div
					onClick={() => navigate("/summary", { state: { date: ontimeEntry.date } })}
					style={{ backgroundColor: "#30E700", marginTop: "-21px", borderRadius: "500px" }}
				>
					.
				</div>
			);
		}

		return null;
	};

	return (
		<div className="User-CSS">
			<ThirdHeader text="History" />
			<div className="history-container" style={{ marginTop: "20px", justifyContent: "center" }}>
				<Calendar
					onChange={handleDateChange}
					value={selectedDate}
					tileContent={({ date }) => handleDateSelection(date)}
					onClickDay={handleDateClick}
					// style={{margin: 'auto'}}
				/>

				<div style={{ marginTop: "20px" }}>
					<div style={{ marginTop: "10px" }}>
						<div>
							<img src={ontime} alt="On Time" style={{ verticalAlign: "middle" }} /> On Time
						</div>
					</div>
					<div style={{ marginTop: "10px" }}>
						<div>
							<img src={clockoutearly} alt="Clock In Late/Clock Out Early" style={{ verticalAlign: "middle" }} /> Clock
							In Late/Clock Out Early
						</div>
					</div>
					<div style={{ marginTop: "10px" }}>
						<div>
							<img src={absent} alt="Absent" style={{ verticalAlign: "middle" }} /> Absence
						</div>
					</div>
					<div style={{ marginTop: "10px" }}>
						<div>
							<img src={onleave} alt="On Leave" style={{ verticalAlign: "middle" }} /> On Leave
						</div>
					</div>
				</div>
			</div>

			<div className="navbar-container">
				<div style={{ display: "flex" }}>
					<div
						className="box-style-3"
						style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "space-around" }}
					>
						<NavLink to="/">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={homeIcon} alt="home" />
								<p>Home</p>
							</div>
						</NavLink>
						<NavLink to="/inventory">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={inventoryIcon} alt="home" />
								<p>Inventory</p>
							</div>
						</NavLink>
						<NavLink to="/schedule">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={scheduleIcon} alt="home" />
								<p>Schedule</p>
							</div>
						</NavLink>
						<NavLink to="/leave">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={leaveIcon} alt="home" />
								<p>Leave</p>
							</div>
						</NavLink>
						<NavLink to="/history" className={({ isActive }) => (isActive ? "navlink-active" : "")}>
							<div style={{ padding: "5px", width: "100%" }}>
								{/* <img style={{ width: '20px', height: '20px' }} src={historyIcon} alt='home' /> */}
								<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										id="Vector"
										d="M9 18C6.7 18 4.69567 17.2373 2.987 15.712C1.27833 14.1867 0.299333 12.2827 0.05 10H2.1C2.33333 11.7333 3.10433 13.1667 4.413 14.3C5.72167 15.4333 7.25067 16 9 16C10.95 16 12.6043 15.3207 13.963 13.962C15.3217 12.6033 16.0007 10.9493 16 9C16 7.05 15.3207 5.39567 13.962 4.037C12.6033 2.67833 10.9493 1.99933 9 2C7.85 2 6.775 2.26667 5.775 2.8C4.775 3.33333 3.93333 4.06667 3.25 5H6V7H0V1H2V3.35C2.85 2.28333 3.88767 1.45833 5.113 0.875C6.33833 0.291667 7.634 0 9 0C10.25 0 11.421 0.237667 12.513 0.713C13.605 1.18833 14.555 1.82967 15.363 2.637C16.171 3.44567 16.8127 4.39567 17.288 5.487C17.7633 6.57833 18.0007 7.74933 18 9C18 10.25 17.7623 11.421 17.287 12.513C16.8117 13.605 16.1703 14.555 15.363 15.363C14.5543 16.171 13.6043 16.8127 12.513 17.288C11.4217 17.7633 10.2507 18.0007 9 18ZM11.8 13.2L8 9.4V4H10V8.6L13.2 11.8L11.8 13.2Z"
										fill="#828C96"
									/>
								</svg>

								<p>History</p>
							</div>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
}

export default History;
