import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../../components/Sidebar';

const UserKpi = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getKPI`)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Promoter Code', dataIndex: 'promoterCode', key: 'promoterCode' },
    { title: 'Bone', dataIndex: 'boneKPI', key: 'boneKPI' },
    { title: 'Customer Reached', dataIndex: 'customerReachedKPI', key: 'customerReachedKPI' },
    { title: 'Homeshelf', dataIndex: 'homeshelfKPI', key: 'reason' },
    { title: 'Monthly Sales', dataIndex: 'monthlySalesKPI', key: 'monthlySalesKPI' },
    { title: 'New Recruit', dataIndex: 'newRecruitKPI', key: 'newRecruitKPI' },
    {
      title: 'Actions',
      dataIndex: 'userId', 
      key: 'actions',
      render: (userId) => (
        <Button onClick={() => handleActionClick(userId)}>Update</Button>
      ),
    },
  ];

  const handleActionClick = (userId) => {
    navigate(`/admin/editUserKPI`, { state: { userId } }); 
  };

  return (
    <div>
      <Sidebar />
      <div className="content">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default UserKpi;
