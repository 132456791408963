import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { saveTokenAdmin, saveAdmin } from "../../src/redux/slices/userSlice";
import { useDispatch } from "react-redux";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(true);
  const [userIdentity, setUserIdentity] = useState(null)

  const handleNavigation = (route) => {
    navigate(route);
  };

  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = localStorage.getItem('admin-token');

        if (!token) {
          navigate("/admin/login");
        }

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/checkTokenAdmin`, { token });
        // console.log(response);
        dispatch(saveAdmin(response.data.data));
        setUserIdentity(response.data.data.userData.username);

        setIsAuth(true);
      } catch (error) {
        console.error(error);
        setIsAuth(false)
      }
    };

    checkToken();
    if (!isAuth) {
      navigate("/admin/login");
    }

  }, [isAuth]);


  return (
    <div className="sidebar">
      <div className="sidebar-header">
        {/* <img style={{width: "65%"}} alt='lol' /> */}
      </div>
      {userIdentity === "dev" ?
        (<ul className="sidebar-menu">
          <li>
            <a onClick={() => handleNavigation("/admin/updatePromoter")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>Update Promoter</span>
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/admin/summaryPerformance")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>Summary</span>
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/admin/workingRecord")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>Working Record</span>
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/admin/leaveApplication")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>Leave Application</span>
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/admin/salesTracking")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>Sales Tracking</span>
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/admin/POSMReport")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>POSM Report</span>
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/admin/uploadSchedule")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>Upload Schedule</span>
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/admin/userKPI")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>User KPI</span>
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/admin/userBrand")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>User Brand</span>
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/admin/premiumRestock")}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>Premium Restock</span>
            </a>
          </li>
          <li>
            <a onClick={() => { handleNavigation("/admin/login"); localStorage.clear(); }}>
              <img style={{ width: "10%", marginRight: "10px" }} /><span>Logout</span>
            </a>
          </li>
        </ul>) : (
          <ul className="sidebar-menu">
            <li>
              <a onClick={() => handleNavigation("/admin/leaveApplication")}>
                <img style={{ width: "10%", marginRight: "10px" }} /><span>Leave Application</span>
              </a>
            </li>
            <li>
              <a onClick={() => handleNavigation("/admin/POSMReport")}>
                <img style={{ width: "10%", marginRight: "10px" }} /><span>POSM Report</span>
              </a>
            </li>
            <li>
              <a onClick={() => { handleNavigation("/admin/login"); localStorage.clear(); }}>
                <img style={{ width: "10%", marginRight: "10px" }} /><span>Logout</span>
              </a>
            </li>
          </ul>
        )}
    </div>
  );
};

export default Sidebar;