import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, message } from "antd";
import Header from "../components/Header";
import axios from "axios";
import { useSelector } from "react-redux";
import centrum from "../assets/centrum.png";
import { useDispatch } from "react-redux";
import { saveToken, saveUser, saveLocation } from "../redux/slices/userSlice";
import { Spin } from "antd";

import home1 from "../assets/home1.png";
import home2 from "../assets/home2.png";
import home3 from "../assets/home3.png";
import home4 from "../assets/home4.png";
import homeIcon from "../assets/svgs/home.svg";
import inventoryIcon from "../assets/svgs/box.svg";
import scheduleIcon from "../assets/svgs/calendar.svg";
import leaveIcon from "../assets/svgs/leave.svg";
import historyIcon from "../assets/svgs/history.svg";
import modalTic from "../assets/modalTick.png";
import arrowUp from "../assets/arrowUp.png";
import arrowDown from "../assets/arrowDown.png";
import topRighTick from "../assets/tickTopRight.png";
import addBone from "../assets/addBone.png";
import goNow from "../assets/goNow.png";

function Home() {
	const promoterId = useSelector(state => state.user.user?.userData.id);
	const promoterCode = useSelector(state => state.user.user?.userData.promoterCode);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [brand, setBrand] = useState([]);
	const [completeBrand, setCompleteBrand] = useState([]);
	const [clockin, setClockin] = useState(0);
	const [lunchTime, setLunchTime] = useState(0);
	const [dinnerTime, setDinnerTime] = useState(0);
	const [clockout, setClockout] = useState(0);
	const [canClockin, setCanClockin] = useState(false);
	const [canLunch, setCanLunch] = useState(false);
	const [canDinner, setCanDinner] = useState(false);
	const [canClockout, setCanClockout] = useState(false);
	const [kpi, setKpi] = useState(null);
	const [totalBone, setTotalBone] = useState(null);
	const [salesUpdate, setSalesUpdate] = useState(true);
	const [totalSales, setTotalSales] = useState(null);
	const [totalBrandComplete, setTotalBrandComplete] = useState(null);
	const [addRoadshow, setAddRoadshow] = useState(true);
	const [totalCustomer, setTotalCustomer] = useState(null);
	const [onLeave, setOnLeave] = useState(false);
	const [attendance, setAttendance] = useState([]);
	const [boneLoading, setBoneLoading] = useState(false);
	const [leaderLoading, setLeaderLoading] = useState(false);
	const [customerLoading, setCustomerLoading] = useState(false);
	const [isAuth, setIsAuth] = useState(true);
	const [noSchedule, setNoSchedule] = useState(null);
	const [totalHomeshelf, setTotalHomeshelf] = useState(null);
	const [totalHomeshelf2, setTotalHomeshelf2] = useState(null);
	const [monthlySales, setMonthlySales] = useState(null);
	const [registeredCompanyUsersLength, setRegisteredCompanyUsersLength] = useState(0);
	const [skuIncentive, setSkuIncentive] = useState(null);
	const [blockedHomeshelf, setBlockedHomeshelf] = useState(null);
	const [blockedSales, setBlockedSales] = useState(null);
	const [blockedBoneCheck, setBlockedBoneCheck] = useState(null);
	const [blockedCustomer, setBlockedCustomer] = useState(null);
	const [blockedDate, setBlockedDate] = useState(null);

	const incentive = registeredCompanyUsersLength * 0.5;

	const incentiveValue = skuIncentive + incentive;

	const [completion, setCompletion] = useState(0);

	useEffect(() => {
		if (blockedHomeshelf === true || blockedSales === true || blockedBoneCheck === true || blockedCustomer === true) {
			navigate("/blocked", {
				state: { data: { blockedBoneCheck, blockedCustomer, blockedHomeshelf, blockedSales, blockedDate } }
			});
		}
	}, [blockedBoneCheck, blockedBoneCheck, blockedCustomer, blockedHomeshelf]);

	useEffect(() => {
		const updateCompletion = () => {
			let newCompletion = 0;

			if (totalBone !== 0) newCompletion++;
			if (totalSales !== 0) newCompletion++;
			if (totalCustomer !== 0) newCompletion++;
			if (totalBrandComplete !== 0) newCompletion++;

			setCompletion(newCompletion);
		};

		updateCompletion();
	}, [totalBone, totalSales, totalCustomer, totalBrandComplete]);

	// if (!promoterId) {
	//   navigate("/login");
	// }

	const currentDate = new Date();
	const formattedDate = currentDate
		.toLocaleDateString("en-GB", {
			day: "numeric",
			month: "short",
			year: "numeric"
		})
		.replace(/ /g, " ");

	const [formData, setFormData] = useState({
		projectLeaderId: null,
		salesAmount: null,
		roadshowDate: null,
		location: null,
		promoterId: promoterId
	});

	const handleInputChange = (e, fieldName) => {
		const { value } = e.target;
		setFormData(prevFormData => ({
			...prevFormData,
			[fieldName]: value
		}));
	};

	const handleSubmit = async event => {
		event.preventDefault();

		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/roadshowSales`, formData, {
				// headers: {
				//   'Content-Type': 'multipart/form-data',
				// },
			});
			console.log("Roadshow sales submitted:", response);

			showModalSuccessSales();
		} catch (error) {
			console.error("Error submitting roadshow sales:", error.response);
		}
	};

	const handleProjectLeader = async event => {
		event.preventDefault();

		try {
			setLeaderLoading(true);
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/checkProjectLeader`, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
			console.log("Roadshow sales submitted:", response.data);

			if (response.status === 200) {
				setLeaderLoading(false);
				showModalSales();
			} else {
				setLeaderLoading(false);
				console.log("error");
			}
		} catch (error) {
			console.error("Error submitting roadshow sales:", error.response.data);
			setLeaderLoading(false);
		}
	};

	const handleEnggagedCustomer = async event => {
		event.preventDefault();

		try {
			setCustomerLoading(true);
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/updateExistingCustomer`,
				{ promoterId, enggagedCustomer, purchasedCustomer, registeredCompanyUsersLength },
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			);
			console.log("Roadshow sales submitted:", response.data);
			setTotalCustomer(response.data.existing);
			handleOkCustomerOpen();
			setCustomerLoading(false);
		} catch (error) {
			console.error("Error submitting roadshow sales:", error.response.data);
			setCustomerLoading(false);
		}
	};

	const [isModalRecordOpen, setIsModalRecordOpen] = useState(false);
	const greenBackground = "#30E700";
	const lightGreenBackground = "#CFEFC7";
	const grayBackground = "#E9E9E9";
	const whiteColor = "#FFFFFF";

	useEffect(() => {
		const checkToken = async () => {
			try {
				const token = localStorage.getItem("haleon-token");

				if (!token) {
					navigate("/login");
				}

				const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/checkToken`, { token });
				// console.log(response.data.data);

				dispatch(saveUser(response.data.data));

				if (response.data.data.clockin === 1) {
					setClockin(1);
				}
				if (response.data.data.lunch === 1) {
					setLunchTime(1);
				}
				if (response.data.data.dinner === 1) {
					setDinnerTime(1);
				}
				if (response.data.data.checkout === 1) {
					setClockout(1);
				}

				if (response.data.data.canUpdateSales === false) {
					setSalesUpdate(false);
				}
				if (response.data.data.addRoadshow === false) {
					setAddRoadshow(false);
				}
				if (response.data.data.onLeave === true) {
					setOnLeave(true);
				}
				setCanClockin(response.data.data.canClockin);
				setCanLunch(response.data.data.canLunch);
				setCanDinner(response.data.data.canDinner);
				setCanClockout(response.data.data.canCheckout);
				setTotalBone(response.data.data.totalBone);
				setTotalSales(response.data.data.totalSales);
				setCompleteBrand(response.data.data.findBrandComplete);
				setTotalCustomer(response.data.data.totalCustomer);
				setAttendance(response.data.data.findAttendance);
				setNoSchedule(response.data.data.noSchedule);
				setTotalHomeshelf2(response.data.data.brandNo);
				setMonthlySales(response.data.data.totalSum);
				setSkuIncentive(response.data.data.skuIncentive);
				setBlockedHomeshelf(response.data.data.blocked_homeshelf);
				setBlockedSales(response.data.data.blocked_sales);
				setBlockedBoneCheck(response.data.data.blocked_bonecheck);
				setBlockedCustomer(response.data.data.blocked_customer);
				setKpi(response.data.data.userKpi);
				setTotalBrandComplete(response.data.data.totalBrandComplete);
				setBlockedDate(response.data.data.blocked_date);
				setIsAuth(true);
			} catch (error) {
				console.error(error);
				setIsAuth(false);
			}
		};

		checkToken();
		if (!isAuth) {
			navigate("/login");
		}
	}, [isAuth]);

	const showModal = () => {
		setIsModalRecordOpen(true);
	};

	const handleOk = () => {
		setIsModalRecordOpen(false);
		navigate("/");
	};

	const handleCancel = () => {
		setIsModalRecordOpen(false);
	};

	const [isModalBoneOpen, setIsModalBoneOpen] = useState(false);

	const showModalBone = () => {
		setIsModalBoneOpen(true);
	};

	const handleCancelBone = () => {
		setIsModalBoneOpen(false);
	};

	const [isModalRoadshowOpen, setIsModalRoadshowOpen] = useState(false);

	const showModalRoadshow = () => {
		setIsModalRoadshowOpen(true);
		setIsModalRecordOpen(false);
	};

	const handleOkRoadshow = () => {
		setIsModalRoadshowOpen(false);
		navigate("/");
	};

	const handleCancelRoadshow = () => {
		setIsModalRoadshowOpen(false);
	};

	const [isModalSalesOpen, setIsModalSalesOpen] = useState(false);

	const showModalSales = () => {
		setIsModalSalesOpen(true);
		setIsModalRoadshowOpen(false);
	};

	const handleOkSales = () => {
		setIsModalSalesOpen(false);
		navigate("/");
	};

	const handleCancelSales = () => {
		setIsModalSalesOpen(false);
	};

	const [isModalSuccessSales, setIsModalSuccessSales] = useState(false);

	const showModalSuccessSales = () => {
		setIsModalSuccessSales(true);
		setIsModalSalesOpen(false);
	};

	const handleOkSuccessSales = () => {
		setIsModalSuccessSales(false);
		navigate("/");
	};

	const handleCancelSuccessSales = () => {
		setIsModalSuccessSales(false);
	};

	const [isModalCustomerOpen, setIsModalCustomerOpen] = useState(false);

	const showModalCustomerOpen = () => {
		setIsModalCustomerOpen(true);
	};

	const handleOkCustomerOpen = () => {
		setIsModalCustomerOpen(false);
		navigate("/");
	};

	const handleCancelCustomerOpen = () => {
		setIsModalCustomerOpen(false);
	};

	const getBrand = async () => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getBrand`, { promoterId });
			// console.log(response.data)
			setBrand(response.data.getBrand);
			setTotalHomeshelf(response.data.brandNo);
		} catch (error) {
			console.error(error);
		}
	};

	const [isModalHomeshelf, setIsModalHomeshelf] = useState(false);

	const showModalHomeshelf = () => {
		getBrand();
		setIsModalHomeshelf(true);
	};

	const handleOkHomeshelf = () => {
		setIsModalHomeshelf(false);
		navigate("/");
	};

	const handleCancelHomeshelf = () => {
		setIsModalHomeshelf(false);
	};

	const [enggagedCustomer, setEnggagedCustomer] = useState(1);
	const [purchasedCustomer, setPurchasedCustomer] = useState(1);

	const handleArrowUp = inputNumber => {
		if (inputNumber === 1) {
			setEnggagedCustomer(prevValue => prevValue + 1);
		}
	};

	const handleArrowDown = inputNumber => {
		if (inputNumber === 1) {
			setEnggagedCustomer(prevValue => Math.max(1, prevValue - 1));
		}
	};

	const handleArrowUp2 = inputNumber => {
		if (inputNumber === 1) {
			setPurchasedCustomer(prevValue => prevValue + 1);
		}
	};

	const handleArrowDown2 = inputNumber => {
		if (inputNumber === 1) {
			setPurchasedCustomer(prevValue => Math.max(1, prevValue - 1));
		}
	};

	const [maleValue, setMaleValue] = useState(0);
	const [femaleValue, setFemaleValue] = useState(0);
	const [normalBoneValue, setNormalBoneValue] = useState(0);
	const [boneLossValue, setBoneLossValue] = useState(0);
	const [osteoporosisValue, setOsteoporosisValue] = useState(0);
	const totalAllBone = parseInt(maleValue) + parseInt(femaleValue);

	const handleOkBone = async () => {
		try {
			setBoneLoading(true);
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addBone`, {
				male: maleValue,
				female: femaleValue,
				normal_bone: normalBoneValue,
				bone_loss: boneLossValue,
				osteoprosis: osteoporosisValue,
				promoterId: promoterId,
				total: totalAllBone
			});

			setIsModalBoneOpen(false);
			setTotalBone(response.data.total);
			setBoneLoading(false);
		} catch (error) {
			setBoneLoading(false);
			console.error("Unexpected error:", error);
			message.error(error.response?.data.error || "An error occurred");
		}
	};

	const Clockin = "Clockin";
	const Lunch = "Lunch";
	const Dinner = "Dinner";
	const Checkout = "Checkout";

	const fetchData = async promoterCode => {
		var myHeaders = new Headers();
		myHeaders.append("x-company-id", process.env.REACT_APP_COMPANY_ID);
		myHeaders.append("x-api-key", process.env.REACT_APP_API_KEY);
		myHeaders.append("x-api-secret", process.env.REACT_APP_API_SECRET);
		myHeaders.append("x-version", "~2");

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow"
		};

		fetch(`${process.env.REACT_APP_DRIVE_URL}/api/promoter/customers?promoterCode=${promoterCode}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result.registeredCompanyUsers);
				setRegisteredCompanyUsersLength(result.registeredCompanyUsers ? result.registeredCompanyUsers.length : 0);
			})
			.catch(error => console.log("error", error));
	};

	fetchData(promoterCode);

	return (
		<div className="User-CSS">
			<Header />
			<div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
				<h1 className="Header">Attendance</h1>
				<p className="text" style={{ textAlign: "left" }}>
					Please record your attendance 30min prior to the start of each checkpoints
				</p>

				<div className="d-flex" style={{ gap: "20px", justifyContent: "center" }}>
					<div
						onClick={
							canClockin === true && noSchedule === false && onLeave === false
								? () => navigate("/attendance", { state: Clockin })
								: null
						}
						className={clockin === 1 ? "circle-style-done" : canClockin === true ? "circle-style-done" : "circle-style"}
					>
						{clockin === 1 ? <img src={topRighTick} alt="Top Right Tick" className="top-right-tick" /> : null}
						<p
							className="text m-0"
							style={{ fontSize: "12px", color: clockin === 1 ? "black" : canClockin === true ? "black" : "#6DDE51" }}
						>
							Clock In
						</p>
						<p
							className="text m-0"
							style={{ color: clockin === 1 ? "black" : canClockin === true ? "black" : "#A8A8A8", fontSize: "8px" }}
						>
							{clockin === 1 ? attendance.checkin_time : "Pending"}
						</p>
					</div>
					<div
						onClick={
							canLunch === true && noSchedule === false && onLeave === false
								? () => navigate("/attendance", { state: Lunch })
								: null
						}
						className={lunchTime === 1 ? "circle-style-done" : canLunch === true ? "circle-style-done" : "circle-style"}
					>
						{lunchTime === 1 ? <img src={topRighTick} alt="Top Right Tick" className="top-right-tick" /> : null}
						<p
							className="text m-0"
							style={{ fontSize: "12px", color: lunchTime === 1 ? "black" : canLunch === true ? "black" : "#6DDE51" }}
						>
							Lunch Clock Out
						</p>
						<p
							className="text m-0"
							style={{ color: lunchTime === 1 ? "black" : canLunch === true ? "black" : "#A8A8A8", fontSize: "8px" }}
						>
							{lunchTime === 1 ? attendance.lunch_time : "Pending"}
						</p>
					</div>
					<div
						onClick={
							canDinner === true && noSchedule === false && onLeave === false
								? () => navigate("/attendance", { state: Dinner })
								: null
						}
						className={
							dinnerTime === 1 ? "circle-style-done" : canDinner === true ? "circle-style-done" : "circle-style"
						}
					>
						{dinnerTime === 1 ? <img src={topRighTick} alt="Top Right Tick" className="top-right-tick" /> : null}
						<p
							className="text m-0"
							style={{ fontSize: "12px", color: dinnerTime === 1 ? "black" : canDinner === true ? "black" : "#6DDE51" }}
						>
							Lunch Clock In
						</p>
						<p
							className="text m-0"
							style={{ color: dinnerTime === 1 ? "black" : canDinner === true ? "black" : "#A8A8A8", fontSize: "8px" }}
						>
							{dinnerTime === 1 ? attendance.dinner_time : "Pending"}
						</p>
					</div>
					<div
						onClick={
							canClockout === true && noSchedule === false && onLeave === false
								? () => navigate("/attendance", { state: Checkout })
								: null
						}
						className={
							clockout === 1 ? "circle-style-done" : canClockout === true ? "circle-style-done" : "circle-style"
						}
					>
						{clockout === 1 ? <img src={topRighTick} alt="Top Right Tick" className="top-right-tick" /> : null}
						<p
							className="text m-0"
							style={{ fontSize: "12px", color: clockout === 1 ? "black" : canClockout === true ? "black" : "#6DDE51" }}
						>
							Clock Out
						</p>
						<p
							className="text m-0"
							style={{ color: clockout === 1 ? "black" : canClockout === true ? "black" : "#A8A8A8", fontSize: "8px" }}
						>
							{clockout === 1 ? attendance.checkout_time : "Pending"}
						</p>
					</div>
				</div>

				<h1 className="Header">Daily Report Submission {completion}/4</h1>

				<div className="d-flex" style={{ gap: "20px" }}>
					<div
						className="box-style text-center"
						onClick={onLeave === false && noSchedule === false && canClockin === false ? showModalHomeshelf : null}
					>
						<img style={{ width: "45px", height: "45px" }} src={home1} alt="img 1" />
						<div className="text-number">
							{totalBrandComplete ? totalBrandComplete : "-"}
							<span style={{ fontSize: "20px" }}>/{totalHomeshelf2 ? totalHomeshelf2 : "-"}</span>
						</div>
						<p className="sub-text">Homeshelf Display</p>
					</div>
					<div
						className="box-style"
						onClick={onLeave === false && noSchedule === false && canClockin === false ? showModalBone : null}
					>
						<img style={{ width: "45px", height: "45px" }} src={home2} alt="img 1" />
						<div className="text-number">
							{totalBone ? totalBone : "-"}
							<span style={{ fontSize: "20px" }}>/{kpi ? kpi.bone_check : "-"}</span>
						</div>
						<p className="sub-text">Bone Check</p>
					</div>
					<div
						className="box-style"
						onClick={onLeave === false && noSchedule === false && canClockin === false ? showModal : null}
					>
						<img style={{ width: "45px", height: "45px" }} src={home3} alt="img 1" />
						<div className="text-number">
							{totalSales ? totalSales : "-"}
							<span style={{ fontSize: "20px" }}>/{kpi ? kpi.sales : "-"}</span>
						</div>
						<p className="sub-text">Record Sales (RM)</p>
					</div>
				</div>

				<div style={{ display: "flex" }}>
					<div className="box-style-2">
						<div className="p-1">
							<div className="text-number">
								{totalCustomer ? totalCustomer : "-"}
								<span style={{ fontSize: "20px" }}>/{kpi ? kpi.customer_reached : "-"}</span>
							</div>
							<div className="sub-text">Customer Reached</div>
						</div>
						<div className="p-1">
							<div style={{ fontSize: "13px", textAlign: "center" }}>
								The sum of new Haleon customer and existing Haleon customer
							</div>
						</div>
						<div className="p-1">
							<img
								onClick={
									onLeave === false && noSchedule === false && canClockin === false ? showModalCustomerOpen : null
								}
								alt="lol"
								src={home4}
							/>
						</div>
					</div>
				</div>

				<h1 className="Header">Month to Date Achievement</h1>
				<h1 className="SubHeader">As of {formattedDate}</h1>

				<div style={{ display: "flex" }}>
					<div className="box-style-3">
						<div style={{ display: "flex" }}>
							<p className="text text-left" style={{ fontWeight: "bold", fontSize: "15px", width: "150px" }}>
								Sales Achievement:
							</p>
							<p className="text text-right">
								RM{monthlySales} / {kpi ? `RM${kpi.monthly_sales}` : "-"}{" "}
								<span style={{ color: "#DB0000" }}>
									({kpi && kpi.monthly_sales ? ((monthlySales / kpi.monthly_sales) * 100).toFixed(2) : 0}%)
								</span>
							</p>
						</div>
						<div style={{ display: "flex" }}>
							<p className="text text-left" style={{ fontWeight: "bold", fontSize: "15px", width: "150px" }}>
								New User Recruited:
							</p>
							<p className="text text-right">
								0 / {kpi ? kpi.new_recruit : "-"} Pax{" "}
								<span style={{ color: "#30E700" }}>
									({kpi && kpi.new_recruit ? ((0 / kpi.new_recruit) * 100).toFixed(2) : 0}%)
								</span>
							</p>
						</div>
						<div style={{ display: "flex" }}>
							<p className="text text-left" style={{ fontWeight: "bold", fontSize: "15px", width: "150px" }}>
								Incentive Accum.:
							</p>
							<p className="text text-right">RM{incentiveValue}</p>
						</div>
						<div style={{ display: "flex" }}>
							<p className="text text-left" style={{ fontWeight: "bold", fontSize: "15px", width: "150px" }}>
								Customer Reached:
							</p>
							<p className="text text-right">
								{totalCustomer ? totalCustomer : "-"} / {kpi ? kpi.monthly_reached : "-"}{" "}
								<span style={{ color: "#DB0000" }}>
									(
									{totalCustomer && kpi && kpi.monthly_reached
										? ((totalCustomer / kpi.monthly_reached) * 100).toFixed(2)
										: 0}
									%)
								</span>
							</p>
						</div>
					</div>
				</div>

				<div style={{ display: "flex" }}>
					<div
						className="box-style-3"
						style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "space-around" }}
					>
						<NavLink to="/" className={({ isActive }) => (isActive ? "navlink-active" : "")}>
							<div style={{ padding: "5px", width: "100%" }}>
								{/* <img style={{ width: "20px", height: "20px" }} src={homeIcon} alt="home" /> */}
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g id="home">
										<path
											id="Vector"
											d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
											// stroke="#919AA2"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											id="Vector_2"
											d="M9 22V12H15V22"
											// stroke="#919AA2"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</g>
								</svg>

								<p>Home</p>
							</div>
						</NavLink>
						<NavLink to="/inventory" className={({ isActive }) => (isActive ? "navlink-active" : "")}>
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={inventoryIcon} alt="home" />
								<p>Inventory</p>
							</div>
						</NavLink>
						<NavLink to="/schedule" className={({ isActive }) => (isActive ? "navlink-active" : "")}>
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={scheduleIcon} alt="home" />
								<p>Schedule</p>
							</div>
						</NavLink>
						<NavLink to="/leave" className={({ isActive }) => (isActive ? "navlink-active" : "")}>
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={leaveIcon} alt="home" />
								<p>Leave</p>
							</div>
						</NavLink>
						<NavLink to="/history" className={({ isActive }) => (isActive ? "navlink-active" : "")}>
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={historyIcon} alt="home" />
								<p>History</p>
							</div>
						</NavLink>
					</div>
				</div>
			</div>

			<Modal
				open={isModalRecordOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
				style={{
					// backgroundColor: '#f7f7f7',
					borderRadius: "20px"
				}}
				width={350}
			>
				<div className="text-center">
					<p className="Header text-center">Record Sales</p>
					<button
						onClick={() => (salesUpdate === true && onLeave === false ? navigate("/upload") : null)}
						style={{ backgroundColor: "white", border: "1px solid black", width: "70%" }}
					>
						Upload Sales Record
					</button>
					<button
						onClick={() => showModalRoadshow()}
						style={{
							backgroundColor: "white",
							border: "1px solid black",
							marginTop: "10px",
							height: "100%",
							width: "70%"
						}}
					>
						Roadshow Sales <br /> (by Project Leader only)
					</button>
				</div>
			</Modal>

			<Modal
				open={isModalBoneOpen}
				onOk={handleOkBone}
				onCancel={handleCancelBone}
				footer={null}
				style={{
					// backgroundColor: '#f7f7f7',
					borderRadius: "20px"
				}}
				width={350}
			>
				<div className="text-center">
					<p className="Header text-center mt-2">Bone Record</p>
					<table style={{ borderCollapse: "collapse", margin: "auto" }}>
						<tbody>
							<tr style={{ background: greenBackground }}>
								<td style={{ padding: "8px", textAlign: "center" }}>Gender</td>
								<td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: "8px" }}>
									Male
								</td>
								<td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: "8px" }}>
									<input
										style={{ width: "100%", textAlign: "center" }}
										type="number"
										value={maleValue}
										onChange={e => setMaleValue(e.target.value)}
									/>
								</td>
							</tr>
							<tr style={{ background: greenBackground }}>
								<td style={{ padding: "8px" }}></td>
								<td style={{ background: "#E9E9E9", border: `1px solid ${whiteColor}`, padding: "8px" }}>Female</td>
								<td style={{ background: "#E9E9E9", border: `1px solid ${whiteColor}`, padding: "8px" }}>
									<input
										style={{ width: "100%", textAlign: "center" }}
										type="number"
										value={femaleValue}
										onChange={e => setFemaleValue(e.target.value)}
									/>
								</td>
							</tr>
							<tr>
								<td style={{ background: "#30E700", padding: "8px", borderTop: `1px solid ${whiteColor}` }}></td>
								<td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: "8px" }}>
									Normal Bone
								</td>
								<td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: "8px" }}>
									<input
										style={{ width: "100%", textAlign: "center" }}
										type="number"
										value={normalBoneValue}
										onChange={e => setNormalBoneValue(e.target.value)}
									/>
								</td>
							</tr>
							<tr>
								<td style={{ background: "#30E700", padding: "8px" }}>Adult Test Result</td>
								<td style={{ background: "#E9E9E9", border: `1px solid ${whiteColor}`, padding: "8px" }}>Bone Loss</td>
								<td style={{ background: "#E9E9E9", border: `1px solid ${whiteColor}`, padding: "8px" }}>
									<input
										style={{ width: "100%", textAlign: "center" }}
										type="number"
										value={boneLossValue}
										onChange={e => setBoneLossValue(e.target.value)}
									/>
								</td>
							</tr>
							<tr>
								<td style={{ background: "#30E700", padding: "8px" }}></td>
								<td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: "8px" }}>
									Osteoporosis
								</td>
								<td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: "8px" }}>
									<input
										style={{ width: "100%", textAlign: "center" }}
										type="number"
										value={osteoporosisValue}
										onChange={e => setOsteoporosisValue(e.target.value)}
									/>
								</td>
							</tr>
							<tr>
								<td style={{ background: "#30E700", padding: "8px" }}></td>
								<td style={{ background: "#E9E9E9", border: `1px solid ${whiteColor}`, padding: "8px" }}>Total</td>
								<td style={{ background: "#E9E9E9", border: `1px solid ${whiteColor}`, padding: "8px" }}>
									{totalAllBone}
								</td>
							</tr>
						</tbody>
					</table>
					<div style={{ textAlign: "right" }}>
						{boneLoading ? (
							<Spin />
						) : (
							<img style={{ marginTop: "10px" }} src={addBone} onClick={() => handleOkBone()} />
						)}
					</div>
				</div>
			</Modal>

			<Modal
				open={isModalRoadshowOpen}
				onOk={handleOkRoadshow}
				onCancel={handleCancelRoadshow}
				footer={null}
				style={{
					// backgroundColor: '#f7f7f7',
					borderRadius: "20px"
				}}
				width={350}
			>
				<div className="text-center">
					<p className="Header text-center">Roadshow Sales</p>
					<form onSubmit={handleProjectLeader}>
						<p style={{ textAlign: "left", fontWeight: "bold" }}>Project Leader ID</p>
						<input
							type="text"
							className="leave-input"
							value={formData.projectLeaderId}
							onChange={e => handleInputChange(e, "projectLeaderId")}
							style={{ border: "1px black solid" }}
						/>
						{leaderLoading ? <Spin /> : <button type="submit">Login</button>}
					</form>
				</div>
			</Modal>

			<Modal
				open={isModalSalesOpen}
				onOk={handleOkSales}
				onCancel={handleCancelSales}
				footer={null}
				style={{
					// backgroundColor: '#f7f7f7',
					borderRadius: "20px"
				}}
				width={350}
			>
				<div className="text-center">
					<form onSubmit={handleSubmit}>
						<p className="Header text-center">Roadshow Sales</p>
						<p style={{ textAlign: "left", fontWeight: "bold" }}>Sales Amount</p>
						<input
							type="text"
							className="leave-input"
							style={{ border: "1px solid black" }}
							value={formData.salesAmount}
							onChange={e => handleInputChange(e, "salesAmount")}
						/>
						<p style={{ textAlign: "left", fontWeight: "bold" }}>Date of Roadshow</p>
						<input
							type="date"
							className="leave-input"
							style={{ border: "1px solid black" }}
							value={formData.roadshowDate}
							onChange={e => handleInputChange(e, "roadshowDate")}
						/>
						<p style={{ textAlign: "left", fontWeight: "bold" }}>Location</p>
						<input
							type="text"
							className="leave-input"
							style={{ border: "1px solid black" }}
							value={formData.location}
							onChange={e => handleInputChange(e, "location")}
						/>
						<button type="submit">Confirm</button>
					</form>
				</div>
			</Modal>
			<Modal
				open={isModalSuccessSales}
				onOk={handleOkSuccessSales}
				onCancel={handleCancelSuccessSales}
				footer={null}
				style={{
					// backgroundColor: '#f7f7f7',
					borderRadius: "20px"
				}}
				width={350}
			>
				<div className="text-center">
					<img style={{ width: "20%" }} src={modalTic} />
					<p className="font-weight-bold mt-2" style={{ fontSize: "15px" }}>
						Success!
					</p>
					<p>Sales has been successfully recorded.</p>
					<button onClick={() => handleOkSuccessSales()}>Done</button>
				</div>
			</Modal>

			<Modal
				open={isModalCustomerOpen}
				onOk={handleOkCustomerOpen}
				onCancel={handleCancelCustomerOpen}
				footer={null}
				style={{
					// backgroundColor: '#f7f7f7',
					borderRadius: "20px"
				}}
				width={350}
			>
				<div className="text-center" style={{ paddingLeft: "25px", paddingRight: "25px" }}>
					{/* <p className="Header text-center">Existing Customer</p> */}
					<p style={{ textAlign: "center" }}>How many customer(s) you have engaged today?</p>
					<form onSubmit={handleEnggagedCustomer}>
						<div className="d-flex justify-content-center align-items-center position-relative">
							<div
								style={{
									position: "absolute",
									top: "50%",
									right: 0,
									transform: "translateY(-50%)",
									display: "grid",
									gap: "10px"
								}}
							>
								<img onClick={() => handleArrowUp(1)} src={arrowUp} />
								<img onClick={() => handleArrowDown(1)} src={arrowDown} />
							</div>
							<div style={{ position: "relative" }}>
								<input
									style={{ height: "100px", width: "100px", fontSize: "30px", textAlign: "center" }}
									value={enggagedCustomer}
									readOnly
								/>
							</div>
						</div>

						{/* <p className="Header text-center mt-2">New Customer</p> */}
						<p style={{ textAlign: "center" }}>How many of them purchased from you today?</p>
						<div className="d-flex justify-content-center align-items-center position-relative">
							<div
								style={{
									position: "absolute",
									top: "50%",
									right: 0,
									transform: "translateY(-50%)",
									display: "grid",
									gap: "10px"
								}}
							>
								<img onClick={() => handleArrowUp2(1)} src={arrowUp} />
								<img onClick={() => handleArrowDown2(1)} src={arrowDown} />
							</div>
							<div style={{ position: "relative" }}>
								<input
									style={{ height: "100px", width: "100px", fontSize: "30px", textAlign: "center" }}
									value={purchasedCustomer}
									readOnly
								/>
							</div>
						</div>

						{customerLoading ? <Spin /> : <button style={{ marginTop: "20px" }}>Save</button>}
					</form>
				</div>
			</Modal>

			<Modal
				open={isModalHomeshelf}
				onOk={handleOkHomeshelf}
				onCancel={handleCancelHomeshelf}
				footer={null}
				style={{
					// backgroundColor: '#f7f7f7',
					borderRadius: "20px"
				}}
				width={350}
			>
				<div>
					<div style={{ display: "flex", justifyContent: "space-around" }}>
						<p className="Header mt-5">Homeshelf Display</p>
						<p className="Header mt-5">
							{totalBrandComplete ? totalBrandComplete : "-"}/{totalHomeshelf}
						</p>
					</div>
					{brand.map(brand => (
						<div key={brand.id} className="box-style-3">
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="mt-2">
								<div className="brand-text text-left">
									<img src={brand.image_url} alt={brand.name} style={{ width: "50px" }} />
									{"      "}
									{brand.name}
								</div>
								{completeBrand.some(completeBrand => completeBrand.id === brand.id) ? (
									<div className="brand-status text-right">Complete</div>
								) : (
									<img
										src={goNow}
										onClick={() =>
											navigate(`/homeshelf`, { state: { brandId: brand.id, userBrandId: brand.promoterBrand[0].id } })
										}
									/>
								)}
							</div>
						</div>
					))}
				</div>
			</Modal>
		</div>
	);
}

export default Home;
