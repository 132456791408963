import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, message, Image, Select } from 'antd';
import Sidebar from '../../components/Sidebar';
const { Option } = Select;

const ValidateSales = () => {
    const location = useLocation();
    const [userData, setUserData] = useState(null);
    const [salesStatus, setSalesStatus] = useState("Pending");
    const [form] = Form.useForm();
    const salesId = location.state.id;

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getSalesInfo`, { id: salesId })
            .then(response => {
                console.log(response);
                setUserData(response.data.data);
                form.setFieldsValue({
                    date: new Date(response.data.data.date).toDateString(),
                    total_sales: response.data.data.total_sales,
                    image: response.data.data.image_url
                });
            })
            .catch(error => {
                console.error(error);
            });
    }, [salesId, form]);

    const [skuItems, setSkuItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [dynamicFields, setDynamicFields] = useState([{ itemName: '', quantity: '', amount: '' }]);

    useEffect(() => {
        // Fetch premium items from your API
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/editSalesSKU`)
            .then(response => {
                console.log(response);
                setSkuItems(response.data.data.map(item => item.name));
            })
            .catch(error => {
                console.error(error);
                message.error('Failed to fetch premium items');
            });
    }, []);

    const handleItemChange = (index, field, value) => {
        const updatedFields = [...dynamicFields];
        updatedFields[index][field] = value;
        setDynamicFields(updatedFields);

        const updatedItems = updatedFields.map(field => ({
            id: field.itemName,  // Assuming your backend expects an 'id' property for premium items
            quantity: field.quantity,
            amount: field.amount,
        }));
        setSelectedItems(updatedItems);
    };


    const addPremiumItem = () => {
        setDynamicFields([...dynamicFields, { itemName: '', quantity: '', amount: '' }]);
    };

    const onFinish = () => {
        const dataToSend = selectedItems.map(item => ({
            id: item.id,  // Assuming your backend expects an 'id' property for premium items
            quantity: item.quantity,
            amount: item.amount
        }));

        console.log(dataToSend);

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/validateSales`, { salesId, data: dataToSend })
            .then(response => {
                console.log(response);
                message.success('Premium values updated successfully');
            })
            .catch(error => {
                console.error(error);
                message.error('Failed to update premium values');
            });
    };

    return (
        <div>
            <Sidebar />
            <div className="content">
                {userData && (
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                    >
                        <Form.Item label="Date" name="date">
                            <Input disabled />
                        </Form.Item>

                        <Form.Item label="Image" name="name">
                            <Image
                                width={200}
                                src={userData.image_url}
                            />
                        </Form.Item>

                        {dynamicFields.map((field, index) => (
                            <div key={index} style={{ marginBottom: '16px' }}>
                                <Form.Item label={`Premium ${index + 1}`}>
                                    <Select
                                        placeholder={`Select premium item ${index + 1}`}
                                        value={field.itemName}
                                        onChange={(value) => handleItemChange(index, 'itemName', value)}
                                    >
                                        {skuItems.map((item, itemIndex) => (
                                            <Option key={itemIndex} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                    <Input
                                        placeholder="Enter quantity"
                                        value={field.quantity}
                                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                    />
                                    <Input
                                        placeholder="Enter amount"
                                        value={field.amount}
                                        onChange={(e) => handleItemChange(index, 'amount', e.target.value)}
                                    />
                                </Form.Item>
                            </div>
                        ))}

                        <Form.Item>
                            <Button type="dashed" onClick={addPremiumItem} style={{ width: '100%' }}>
                                Add Premium Item
                            </Button>
                        </Form.Item>

                        {salesStatus === "Rejected" && (
                            <Form.Item label="Rejected Reason" name="rejectedReason" rules={[{ required: true, message: 'Please provide a rejected reason' }]}>
                                <Input.TextArea />
                            </Form.Item>
                        )}

                        <Form.Item>
                            <Button type="primary" htmlType="submit" onClick={() => setSalesStatus("Approved")}>
                                Approve
                            </Button>
                            <Button type="danger" htmlType="submit" onClick={() => setSalesStatus("Rejected")}>
                                Reject
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default ValidateSales;
