import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import Sidebar from '../../components/Sidebar';

const EditKpi = () => {
    const location = useLocation();
    const [userData, setUserData] = useState(null);
    const [form] = Form.useForm();
    console.log(userData);
    const userId = location.state.userId;

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getUserKPI`, { id: userId })
            .then(response => {
                setUserData(response.data.data);
                form.setFieldsValue({
                    name: response.data.data.name,
                    boneKPI: response.data.data.kpi[0].bone_check,
                    salesKPI: response.data.data.kpi[0].sales,
                    customerReachKPI: response.data.data.kpi[0].customer_reached,
                    homeshelfKPI: response.data.data.kpi[0].homeshelf_display,
                    monthlySalesKPI: response.data.data.kpi[0].monthly_sales,
                    newRecruitKPI: response.data.data.kpi[0].new_recruit,
                    monthlyReached: response.data.data.kpi[0].monthly_reached
                });
            })
            .catch(error => {
                console.error(error);
            });
    }, [userId, form]);


    const handleUpdateKPI = async (values) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateKPI`, {
                id: userData.id,
                ...values,
            });

            if (response.status === 200) {
                message.success('KPI updated successfully');
            } else {
                message.error('Failed to update KPI');
            }
        } catch (error) {
            console.error(error);
            message.error('Internal server error');
        }
    };

    return (
        <div>
            <Sidebar />
            <div className="content">
                {userData && (
                    <Form
                        form={form}
                        onFinish={handleUpdateKPI}
                        layout="vertical"
                    >
                        <Form.Item label="Name" name="name">
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="Bone KPI" name="boneKPI">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Sales KPI" name="salesKPI">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Customer Reach KPI" name="customerReachKPI">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Homeshelf KPI" name="homeshelfKPI">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Monthly Sales KPI" name="monthlySalesKPI">
                            <Input />
                        </Form.Item>
                        <Form.Item label="New Recruit KPI" name="newRecruitKPI">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Monthly Customer Reached KPI" name="monthlyReached">
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update KPI
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default EditKpi;
