import React, { useState } from "react";
import Header from "../../components/Header";
import axios from "axios";
import addBone from "../../assets/addBone.png";
import { Modal, message, Spin } from 'antd';
import { Navigate, useLocation, useNavigate } from "react-router-dom";

function BoneCheck() {

  const location = useLocation();
  const navigate = useNavigate();
  const promoterId = location.state.promoterId;
  const [maleValue, setMaleValue] = useState(0);
  const [femaleValue, setFemaleValue] = useState(0);
  const [normalBoneValue, setNormalBoneValue] = useState(0);
  const [boneLossValue, setBoneLossValue] = useState(0);
  const [osteoporosisValue, setOsteoporosisValue] = useState(0);
  const totalAllBone = parseInt(maleValue) + parseInt(femaleValue);
  const greenBackground = '#30E700';
  const lightGreenBackground = '#CFEFC7';
  const grayBackground = '#E9E9E9';
  const whiteColor = '#FFFFFF';
  // const yesterdayDate = location.state.yesterdayDate;
  const yesterdayDate = location.state.yesterdayDate ? new Date(location.state.yesterdayDate) : new Date(location.state.yesterdayDate);

  console.log(yesterdayDate);
  const handleOkBone = async () => {
    try {
      // setBoneLoading(true);
      const postData = {
        male: maleValue,
        female: femaleValue,
        normal_bone: normalBoneValue,
        bone_loss: boneLossValue,
        osteoprosis: osteoporosisValue,
        promoterId: promoterId,
        total: totalAllBone,
      };

      if (yesterdayDate) {
        postData.yesterdayDate = yesterdayDate;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addBone`, postData);

      console.log(response);
      if (response) {
        navigate("/")
      }

      // setIsModalBoneOpen(false);
      // setTotalBone(response.data.total);
      // setBoneLoading(false);
    } catch (error) {
      // setBoneLoading(false);
      console.error('Unexpected error:', error);
      message.error(error.response?.data.error || 'An error occurred');
    }
  };
  
  return (
    <div>
      <Header />
      <div className="text-center">
        <div className='text-center' style={{paddingLeft: '25px', paddingRight: '25px'}}>
          <p className="Header text-center mt-2">Bone Record</p>
          <table style={{ borderCollapse: 'collapse', margin: 'auto' }}>
            <tbody>
              <tr style={{ background: greenBackground }}>
                <td style={{ padding: '8px', textAlign: 'center' }}>Gender</td>
                <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>Male</td>
                <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>
                  <input style={{ width: '100%', textAlign: 'center' }}
                    type="number"
                    value={maleValue}
                    onChange={(e) => setMaleValue(e.target.value)}
                  /></td>
              </tr>
              <tr style={{ background: greenBackground }}>
                <td style={{ padding: '8px' }}></td>
                <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>Female</td>
                <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>
                  <input style={{ width: '100%', textAlign: 'center' }}
                    type="number"
                    value={femaleValue}
                    onChange={(e) => setFemaleValue(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ background: '#30E700', padding: '8px', borderTop: `1px solid ${whiteColor}` }}></td>
                <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>Normal Bone</td>
                <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>
                  <input style={{ width: '100%', textAlign: 'center' }}
                    type="number"
                    value={normalBoneValue}
                    onChange={(e) => setNormalBoneValue(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ background: '#30E700', padding: '8px' }}>Adult Test Result</td>
                <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>Bone Loss</td>
                <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>
                  <input style={{ width: '100%', textAlign: 'center' }}
                    type="number"
                    value={boneLossValue}
                    onChange={(e) => setBoneLossValue(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ background: '#30E700', padding: '8px' }}></td>
                <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>Osteoporosis</td>
                <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>
                  <input style={{ width: '100%', textAlign: 'center' }}
                    type="number"
                    value={osteoporosisValue}
                    onChange={(e) => setOsteoporosisValue(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ background: '#30E700', padding: '8px' }}></td>
                <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>Total</td>
                <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>{totalAllBone}</td>
              </tr>
            </tbody>
          </table>
          <div style={{ textAlign: 'right' }}>
            {/* {boneLoading ?
              (<Spin />) : */}
            {/* (<img style={{ marginTop: "10px" }} src={addBone} onClick={() => handleOkBone()} />) */}
            {/* } */}
            <button
              style={{ backgroundColor: "#30E700", color: "black", marginTop: '20px' }}
              onClick={() => handleOkBone()}
            >
              Save Record
            </button>
            <button
              style={{ backgroundColor: "black", color: "white", marginTop: '20px' }}
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoneCheck;
