import demoImage from "../assets/demoImage.png"
import SecondHeader from '../components/SecondHeader';
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

function Sales() {

    const [salesDetails, setSalesDetails] = useState(null);
    const [roadshowDetails, setRoadshowDetails] = useState(null);
    const [signedUrl, setSignedUrl] = useState(null);
    const location = useLocation();

    console.log(location.state);

    const salesId = location.state && location.state.sales ? location.state.sales.id : null;
    const roadshowId = location.state && location.state.roadshowSales ? location.state.roadshowSales.id : null;


    useEffect(() => {
        const fetchSalesDetails = async () => {
            try {
                if (salesId) {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getSales`, { salesId });
                    console.log(response);
                    setSalesDetails(response.data.findSales);
                    setSignedUrl(response.data.signedUrl);
                }
            } catch (error) {
                console.error('Error fetching sales details:', error);
            }
        };

        fetchSalesDetails();
    }, [salesId]);

    useEffect(() => {
        const fetchRoadshowDetails = async () => {
            try {
                if (roadshowId) {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getRoadshowSales`, { roadshowId });
                    console.log(response);
                    setRoadshowDetails(response.data);
                }
            } catch (error) {
                console.error('Error fetching roadshow details:', error);
            }
        };

        fetchRoadshowDetails();
    }, [roadshowId]);

    return (
        <div className='User-CSS'>
            <SecondHeader text="Sales" />
            <div className="status-leave-container">
                <div className="font-weight-bold mt-3">Sales Report</div>
                {salesDetails ?
                    (<div className="box-style-3">
                        <div>
                            <div className="font-weight-bold mt-3">Report Submitted:</div>
                            <img style={{ width: '250px' }} src={signedUrl} alt="Medical Certificate" />
                        </div>
                        <div>
                            <div className="font-weight-bold mt-3">Premium Giveaway:</div>
                            {salesDetails && salesDetails.stock && (
                                <div className="mt-2">
                                    {salesDetails.stock.map((stock, index) => (
                                        <div key={index}>
                                            {stock.premium.name} x{stock.skuQuantity}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="font-weight-bold mt-3">Total Sales:</div>
                            <div className="mt-2">{salesDetails ? `RM${salesDetails.total_sales}` : "-"}</div>
                        </div>
                        <div>
                            <div className="font-weight-bold mt-3">Status:</div>
                            <div style={{ color: '#3C802B' }} className="mt-2">{salesDetails ? salesDetails.status : "-"}</div>
                        </div>
                    </div>) : null}
                {roadshowDetails ?
                    (<div className="box-style-3">
                        <div className="font-weight-bold mt-3">Roadshow Sales</div>
                        <div>
                            <div className="font-weight-bold mt-3">Project Leader:</div>
                            <div className="mt-2">{roadshowDetails ? roadshowDetails.projectLeaderId : "-"}</div>
                        </div>
                        <div>
                            <div className="font-weight-bold mt-3">Sales Amount:</div>
                            <div className="mt-2">{roadshowDetails ? roadshowDetails.roadshow_amount : "-"}</div>
                        </div>
                        <div>
                            <div className="font-weight-bold mt-3">Location:</div>
                            <div className="mt-2">{roadshowDetails ? roadshowDetails.roadshow_location : "-"}</div>
                        </div>
                        <div>
                            <div className="font-weight-bold mt-3">Status:</div>
                            <div style={{ color: '#3C802B' }} className="mt-2">Approved</div>
                        </div>
                    </div>) : null}
            </div>
        </div>
    )
}

export default Sales
