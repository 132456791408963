import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, message, Image } from 'antd';
import Sidebar from '../../components/Sidebar';

const ValidateLeave = () => {
    const location = useLocation();
    const [userData, setUserData] = useState(null);
    const [leaveStatus, setLeaveStatus] = useState("Pending");
    const [form] = Form.useForm();
    const leaveId = location.state.id;

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getLeaveInfo`, { id: leaveId })
            .then(response => {
                setUserData(response.data.data);
                form.setFieldsValue({
                    date: new Date(response.data.data.date).toDateString(),
                    type: response.data.data.type,
                    reason: response.data.data.reason,
                    image: response.data.data.image_url
                });
            })
            .catch(error => {
                console.error(error);
            });
    }, [leaveId, form]);

    const onFinish = () => {
        const formData = form.getFieldsValue();
        const { rejectedReason } = formData;

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/validateLeave`, {
            id: leaveId,
            status: leaveStatus,
            rejected_reason: leaveStatus === "Rejected" ? rejectedReason : undefined,
        })
            .then(response => {
                message.success("Leave validated successfully");
            })
            .catch(error => {
                message.error("Failed to validate leave");
                console.error(error);
            });
    };

    return (
        <div>
            <Sidebar />
            <div className="content">
                {userData && (
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                    >
                        <Form.Item label="Date" name="date">
                            <Input disabled />
                        </Form.Item>

                        <Form.Item label="Type" name="type">
                            <Input disabled />
                        </Form.Item>

                        <Form.Item label="Image" name="name">
                        <Image
                            width={200}
                            src={userData.image_url}
                        />
                        </Form.Item>

                        <Form.Item label="Reason" name="reason">
                            <Input.TextArea disabled />
                        </Form.Item>

                        {leaveStatus === "Rejected" && (
                            <Form.Item label="Rejected Reason" name="rejectedReason" rules={[{ required: true, message: 'Please provide a rejected reason' }]}>
                                <Input.TextArea />
                            </Form.Item>
                        )}

                        <Form.Item>
                            <Button type="primary" htmlType="submit" onClick={() => setLeaveStatus("Approved")}>
                                Approve
                            </Button>
                            <Button type="danger" htmlType="submit" onClick={() => setLeaveStatus("Rejected")}>
                                Reject
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default ValidateLeave;
