import React, { useState, useEffect } from "react";
import uploadImage from "../assets/uploadMedical.png";
import { Modal, Spin } from "antd";
import modalTic from "../assets/modalTick.png";
import ThirdHeader from "../components/ThirdHeader";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import homeIcon from "../assets/svgs/home.svg";
import inventoryIcon from "../assets/svgs/box.svg";
import scheduleIcon from "../assets/svgs/calendar.svg";
import leaveIcon from "../assets/svgs/leave.svg";
import historyIcon from "../assets/svgs/history.svg";

const Leave = () => {
	const promoterId = useSelector(state => state.user.user?.userData.id);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [typeError, setTypeError] = useState(null);

	console.log(promoterId);

	if (!promoterId) {
		navigate("/");
	}
	const [formData, setFormData] = useState({
		date: getTodayDate(),
		type: "",
		reason: "",
		certificateImage: null,
		promoterId: promoterId
	});

	const [showMedicalCertificate, setShowMedicalCertificate] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (formData.type === "EmergencyLeave") {
			setTypeError("If you choose emergency leave, your attendance will not be recorded");
		} else {
			setTypeError(null); // Reset the error state if the condition is not met
		}
	}, [formData.type]);

	const showModal = () => setIsModalOpen(true);
	const handleOk = () => {
		setIsModalOpen(false);
		navigate("/");
	};
	const handleCancel = () => setIsModalOpen(false);

	const handleLeaveTypeChange = event => {
		const selectedLeaveType = event.target.value;
		setFormData({
			...formData,
			type: selectedLeaveType
		});

		setShowMedicalCertificate(selectedLeaveType === "MedicalLeave");
	};

	const handleImageClick = () => {
		document.getElementById("certificateImageInput").click();
	};

	const handleInputChange = event => {
		const { name, value, type } = event.target;
		const inputValue = type === "file" ? event.target.files[0] : value;

		setFormData({
			...formData,
			[name]: inputValue
		});
	};

	const handleSubmit = async event => {
		event.preventDefault();

		const selectedDate = new Date(formData.date);
		const minDate = new Date();
		minDate.setDate(minDate.getDate() + 14);
		console.log(selectedDate);
		console.log(minDate);

		if (formData.type === "AnnualLeave" && selectedDate < minDate) {
			setError("Error: Annual Leave only can be applied 14 days in advance");
			return;
		} else {
			setError(null); // Reset the error state if the condition is not met
		}

		try {
			setLoading(true);
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});

			console.log("Leave application submitted:", response.data);
			showModal();
		} catch (error) {
			setLoading(false);
			console.error("Error submitting leave application:", error.response.data);
		}
	};

	return (
		<div className="User-CSS">
			<ThirdHeader text="Apply Leave" />
			<div className="leave-container">
				<form onSubmit={handleSubmit}>
					<label className="leave-label">Date of leave</label>
					<input
						type="date"
						name="date"
						className="leave-input"
						htmlFor="date"
						value={formData.date}
						// min={getFutureDate(14)}
						onChange={handleInputChange}
					/>
					{error && <p style={{ color: "red" }}>{error}</p>}
					{/* //break here */}
					<p></p>
					<label className="leave-label">Type of leave</label>
					<select
						name="type"
						className="leave-input"
						htmlFor="type"
						value={formData.type}
						onChange={handleLeaveTypeChange}
					>
						<option value="">Select leave type</option>
						<option value="AnnualLeave">Annual Leave</option>
						<option value="MedicalLeave">Medical Leave</option>
						<option value="EmergencyLeave">Emergency Leave</option>
					</select>

					{typeError && <p style={{ color: "red" }}>{typeError}</p>}

					<label className="leave-label">Reason</label>
					<input
						name="reason"
						placeholder="State your reason here"
						type="text"
						className="leave-input"
						htmlFor="reason"
						value={formData.reason}
						onChange={handleInputChange}
					/>

					{showMedicalCertificate && (
						<div>
							<label className="leave-label">Medical Certificate (For Medical Leave)</label>
							<div className="box-style-3 text-center" style={{ padding: "15%" }} onClick={handleImageClick}>
								{formData.certificateImage ? (
									<img
										src={URL.createObjectURL(formData.certificateImage)}
										alt="Medical Certificate"
										style={{ maxWidth: "100%", marginTop: "10px" }}
									/>
								) : (
									<div>
										<input
											type="file"
											name="certificateImage"
											id="certificateImageInput"
											accept="image/*"
											style={{ display: "none" }}
											onChange={handleInputChange}
										/>
										<img src={uploadImage} alt="Upload Medical Certificate" />
										<p className="text-center">Upload a photo of the medical certificate</p>
									</div>
								)}
							</div>
						</div>
					)}

					{loading ? <Spin /> : <button type="submit">Submit</button>}
				</form>

				<Modal
					open={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={null}
					style={{
						// backgroundColor: '#f7f7f7',
						borderRadius: "20px"
					}}
					width={350}
				>
					<div className="text-center">
						<img style={{ width: "20%" }} src={modalTic} alt="Success" />
						<p className="font-weight-bold mt-2" style={{ fontSize: "15px" }}>
							Success!
						</p>
						<p>Leave has been submitted. Go to “History” to check approval status.</p>
						<button onClick={handleOk}>Done</button>
					</div>
				</Modal>
			</div>

			<div className="navbar-container">
				<div style={{ display: "flex" }}>
					<div
						className="box-style-3"
						style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "space-around" }}
					>
						<NavLink to="/">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={homeIcon} alt="home" />
								<p>Home</p>
							</div>
						</NavLink>
						<NavLink to="/inventory">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={inventoryIcon} alt="home" />
								<p>Inventory</p>
							</div>
						</NavLink>
						<NavLink to="/schedule">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={scheduleIcon} alt="home" />
								<p>Schedule</p>
							</div>
						</NavLink>
						<NavLink to="/leave" className={({ isActive }) => (isActive ? "navlink-active" : "")}>
							<div style={{ padding: "5px", width: "100%" }}>
								{/* <img style={{ width: '20px', height: '20px' }} src={leaveIcon} alt='home' /> */}
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g id="trello">
										<path
											id="Vector"
											d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
											// stroke="#828C96"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											id="Vector_2"
											d="M10 7H7V16H10V7Z"
											// stroke="#828C96"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											id="Vector_3"
											d="M17 7H14V12H17V7Z"
											// stroke="#828C96"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</g>
								</svg>

								<p>Leave</p>
							</div>
						</NavLink>
						<NavLink to="/history">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={historyIcon} alt="home" />
								<p>History</p>
							</div>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
};

const getTodayDate = () => {
	const today = new Date();
	const year = today.getFullYear();
	let month = (today.getMonth() + 1).toString().padStart(2, "0");
	let day = today.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

const getFutureDate = days => {
	const futureDate = new Date();
	futureDate.setDate(futureDate.getDate() + days);
	const year = futureDate.getFullYear();
	let month = (futureDate.getMonth() + 1).toString().padStart(2, "0");
	let day = futureDate.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

export default Leave;
