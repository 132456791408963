import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';

const Homepage = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();


  return (
    <div className="homepage">
      <Sidebar />

      {/* Content Container */}
      <div className="content">
        
      </div>
    </div>
  );
};

export default Homepage;