import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux'; // Import the Provider
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { logoutUser, saveUser } from "./redux/slices/userSlice";
import store from "../src/redux/store"
import Attendance from "../src/pages/attendance";
import Blocked from "../src/pages/blocked";
import Bone from "../src/pages/bone";
import History from "../src/pages/history";
import Home from "../src/pages/home";
import Homeshelf from "../src/pages/homeshelf";
import Inventory from "../src/pages/inventory";
import Leave from "../src/pages/leave";
import LeaveStatus from "../src/pages/leaveStatus";
import Login from "../src/pages/login";
import Record from "../src/pages/record";
import Sales from "../src/pages/sales";
import Schedule from "../src/pages/schedule";
import Summary from "../src/pages/summary";
import Upload from "../src/pages/upload";
import AdminLogin from "../src/pages/admin/login";
import AdminHome from "../src/pages/admin/home";
import SummaryPerformance from "../src/pages/admin/summaryPerformance"
import LeaveApplication from "../src/pages/admin/leaveApplication";
import SalesTracking from "../src/pages/admin/salesTracking";
import UploadSchedule from "../src/pages/admin/uploadSchedule";
import UserKPI from "../src/pages/admin/userKPI";
import EditUserKPI from "../src/pages/admin/editKPI";
import UserBrand from "../src/pages/admin/userBrand";
import EditUserBrand from "../src/pages/admin/editBrand";
import PremiumRestock from '../src/pages/admin/premiumRestock';
import EditRestockPremium from "../src/pages/admin/editPremium";
import GetPromoter from "../src/pages/admin/getPromoter";
import UpdatePromoter from "../src/pages/admin/editPromoter";
import ValidateLeave from "../src/pages/admin/validateLeave";
import WorkingRecord from "../src/pages/admin/workingRecord";
import POSMReport from "../src/pages/admin/posmReport.js";
import ValidateSales from "../src/pages/admin/validateSales";
import Hotline from "../src/components/HotlineButton.js";
import BoneCheck from "../src/pages/reupload/bonecheck";
import CustomerReached from "../src/pages/reupload/customerReaches";
import ReuploadHomeshelf from "../src/pages/reupload/homeshelf";
import InventoryBalance from '../src/pages/inventory/inventoryBalance.js';
import InventoryOnHand from '../src/pages/inventory/inventoryOnHand.js';

function App() {

  const shouldRenderHotline = !window.location.pathname.includes('/admin');


  // const [isAuthenticated, setIsAuthenticated] = useState(true)

  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const token = localStorage.getItem('haleon-token');
  //       const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/checkToken`, { token });

  //       setIsAuthenticated(true);
  //       console.log(response);

  //     } catch (error) {

  //       setIsAuthenticated(false);
  //       console.error(error);
  //     }
  //   };

  //   checkToken();
  // }, []);

  return (
    <Provider store={store}>
      <div className="App">
      {shouldRenderHotline && <Hotline />}
        <BrowserRouter>
          <Routes>
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/blocked" element={<Blocked />} />
            <Route path="/bone" element={<Bone />} />
            <Route path="/history" element={<History />} />
            <Route path="/" element={<Home />} />
            <Route path="/homeshelf" element={<Homeshelf />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/leave" element={<Leave />} />
            <Route path="/leaveStatus" element={<LeaveStatus />} />
            <Route path="/login" element={<Login />} />
            <Route path="/record" element={<Record />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/summary" element={<Summary />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/home" element={<AdminHome />} />
            <Route path="/admin/summaryPerformance" element={<SummaryPerformance />} />
            <Route path="/admin/leaveApplication" element={<LeaveApplication />} />
            <Route path="/admin/salesTracking" element={<SalesTracking />} />
            <Route path="/admin/uploadSchedule" element={<UploadSchedule />} />
            <Route path="/admin/userKPI" element={<UserKPI /> } />
            <Route path="/admin/editUserKPI" element={<EditUserKPI /> } />
            <Route path="/admin/userBrand" element={<UserBrand />} />
            <Route path="/admin/editUserBrand" element={<EditUserBrand /> } />
            <Route path="/admin/premiumRestock" element={<PremiumRestock />} />
            <Route path="/admin/editRestockPremium" element={<EditRestockPremium /> } />
            <Route path="/admin/updatePromoter" element={<GetPromoter /> } />
            <Route path="/admin/editPromoter" element={<UpdatePromoter /> } />
            <Route path="/admin/ValidateLeave" element={<ValidateLeave /> } />
            <Route path="/admin/WorkingRecord" element={<WorkingRecord /> } />
            <Route path="/admin/POSMReport" element= {<POSMReport /> } />
            <Route path="/admin/ValidateSales" element={<ValidateSales /> } />
            <Route path="/bonecheck" element={<BoneCheck />} />
            <Route path="/customerReached" element={<CustomerReached />} />
            <Route path="/reuploadHomeshelf" element={<ReuploadHomeshelf />} />
            <Route path="/inventoryBalance" element={<InventoryBalance />} />
            <Route path="/inventoryOnHand" element={<InventoryOnHand />} />
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
