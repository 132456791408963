import React, { useState } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Sidebar from '../../components/Sidebar';
import axios from 'axios';

const UploadSchedule = () => {
  const [fileList, setFileList] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);

  console.log(fileList);

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('file', file.originFileObj);
      });

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/uploadSchedule`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        message.success('File uploaded successfully');
      } else {
        message.error('File upload failed');
      }
    } catch (error) {
      console.error(error);
      message.error('Internal error');
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="content">
        <Upload fileList={fileList} showUploadList={false} beforeUpload={() => false} onChange={({ file, fileList }) => setFileList([...fileList])}>
          <Button icon={<UploadOutlined />}>Choose File</Button>
        </Upload>
        <Button
          type="primary"
          onClick={() => setSubmitClicked(true)}
          style={{ marginTop: 16 }}
        >
          Submit
        </Button>
        {submitClicked && (
          <Button type="primary" onClick={handleUpload} style={{ marginLeft: 16 }}>
            Upload Schedule CSV
          </Button>
        )}
      </div>
    </div>
  );
};

export default UploadSchedule;
