import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("haleon-token") || null,
  user: null,
  isAuthenticated: false,
  location: null,
  adminToken: localStorage.getItem("admin-token") || null,
};



const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser: (state, action) => {
      // console.log(action, state);
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    },
    saveToken: (state, action) => {
      localStorage.setItem("haleon-token", action.payload.token);
      return {
        ...state,
        token: action.payload.token
      };
    },
    saveLocation: (state, action) => {
      localStorage.setItem("location", action.payload.location);
      return {
        ...state,
        location: action.payload.location
      };
    },
    logoutUser: () => {
      localStorage.removeItem('haleon-token');
    },
    saveAdmin: (state, action) => {
      // console.log(action, state);
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    },
    saveTokenAdmin: (state, action) => {
      localStorage.setItem("haleon-token", action.payload.token);
      return {
        ...state,
        token: action.payload.token
      };
    },
  },
  extraReducers: (builder) => { },
});

export const { saveToken, saveUser, logoutUser, saveAdmin, saveTokenAdmin } =
  userSlice.actions;
export default userSlice.reducer;
