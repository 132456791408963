import React, { useState, useEffect } from 'react';
import SecondHeader from '../components/SecondHeader';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function Bone() {
  const greenBackground = '#30E700';
  const lightGreenBackground = '#CFEFC7';
  const grayBackground = '#E9E9E9';
  const whiteColor = '#FFFFFF';

  const location = useLocation();
  const boneId = location.state && location.state.bone ? location.state.bone.id : null;
  const [bone, setBone] = useState([]);

  console.log(bone);


  useEffect(() => {
    const getBone = async () => {
      try {
        if(boneId) {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getBone`, { boneId });
        setBone(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getBone();
  }, []);

  return (
    <div className='User-CSS'>
      <SecondHeader text="Bone Check" />
      <div className="bone-container">
        <table style={{ borderCollapse: 'collapse', margin: 'auto' }}>
          <tbody>
            <tr style={{ background: greenBackground }}>
              <td style={{ padding: '8px', textAlign: 'center' }}>Gender</td>
              <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>Male</td>
              <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>{bone?.male}</td>
            </tr>
            <tr style={{ background: greenBackground }}>
              <td style={{  padding: '8px' }}></td>
              <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>Female</td>
              <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>{bone?.female}</td>
            </tr>
            <tr>
              <td style={{ background: '#30E700', padding: '8px', borderTop: `1px solid ${whiteColor}`  }}></td>
              <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>Normal Bone</td>
              <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>{bone?.normal_bone}</td>
            </tr>
            <tr>
              <td style={{ background: '#30E700', padding: '8px' }}>Adult Test Result</td>
              <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>Bone Loss</td>
              <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>{bone?.bone_loss}</td>
            </tr>
            <tr>
              <td style={{ background: '#30E700', padding: '8px' }}></td>
              <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>Osteoporosis</td>
              <td style={{ background: lightGreenBackground, border: `1px solid ${whiteColor}`, padding: '8px' }}>{bone?.osteoprosis}</td>
            </tr>
            <tr>
              <td style={{ background: '#30E700', padding: '8px' }}></td>
              <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>Total</td>
              <td style={{ background: '#E9E9E9', border: `1px solid ${whiteColor}`, padding: '8px' }}>{bone?.total}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Bone;
