import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import homeIcon from "../assets/svgs/home.svg";
import inventoryIcon from "../assets/svgs/box.svg";
import scheduleIcon from "../assets/svgs/calendar.svg";
import leaveIcon from "../assets/svgs/leave.svg";
import historyIcon from "../assets/svgs/history.svg";
import ThirdHeader from "../components/ThirdHeader";
import collapseIcon from "../assets/collapseIcon.png";

function Inventory() {
	const promoterId = useSelector(state => state.user.user?.userData.id);
	const [inventoryData, setInventoryData] = useState([]);
	const [balanceData, setBalanceData] = useState([]);
	const [totalNumber, setTotalNumber] = useState();
	const [positiveNumber, setPositiveNumber] = useState();
	const [totalBalance, setTotalBalance] = useState();

	console.log(inventoryData);

	const navigate = useNavigate();

	if (!promoterId) {
		navigate("/");
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getInventory`, {
					promoterId: promoterId
				});

				console.log(response);

				if (response.status === 200) {
					setInventoryData(response.data.todayInventory);
					setTotalNumber(response.data.negativeSum);
					setBalanceData(response.data.balanceSku);
					setPositiveNumber(response.data.positiveSum);
					setTotalBalance(response.data.totalBalance);
				} else {
					console.error("Failed to fetch inventory data");
				}
			} catch (error) {
				console.error("Error fetching inventory data", error);
			}
		};

		fetchData();
	}, [promoterId]);

	const today = new Date();

	return (
		<div className="User-CSS">
			<ThirdHeader text="Inventory" />
			<div className="inventory-container" style={{ marginBottom: "100px" }}>
				<p className="Header">Premium Inventory</p>

				<div>
					<div className="box-style-3">
						<div
							onClick={() => navigate("/inventoryOnHand", { state: inventoryData })}
							style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
						>
							<p style={{ margin: 0, fontWeight: "300" }} className="summary-header text-left">
								Initial Premium On Hand
							</p>
							<p style={{ margin: 0 }} className="text text-right">
								<span style={{ fontWeight: "600", fontSize: "24px", marginRight: "10px" }}>
									{positiveNumber ? positiveNumber : "0"}
								</span>
								<img src={collapseIcon} />
							</p>
						</div>
					</div>
					<div className="box-style-3">
						<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
							<p style={{ margin: 0, fontWeight: "300" }} className="summary-header text-left">
								Premium Given Away
							</p>
							<p style={{ margin: 0 }} className="text text-right">
								<span style={{ fontWeight: "600", fontSize: "24px", marginRight: "10px" }}>
									{totalNumber ? totalNumber : "0"}
								</span>
								<img style={{ opacity: 0 }} src={collapseIcon} />
							</p>
						</div>
					</div>

					<div className="box-style-3">
						<div
							onClick={() => navigate("/inventoryBalance", { state: balanceData })}
							style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
						>
							<p style={{ margin: 0, fontWeight: "300" }} className="summary-header text-left">
								Balance Premium
							</p>
							<p style={{ margin: 0 }} className="text text-right">
								<span style={{ fontWeight: "600", fontSize: "24px", marginRight: "10px" }}>
									{totalBalance ? totalBalance : "0"}
								</span>
								<img src={collapseIcon} />
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="navbar-container">
				<div style={{ display: "flex" }}>
					<div
						className="box-style-3"
						style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "space-around" }}
					>
						<NavLink to="/">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={homeIcon} alt="home" />
								<p>Home</p>
							</div>
						</NavLink>
						<NavLink to="/inventory" className={({ isActive }) => (isActive ? "navlink-active" : "")}>
							<div style={{ padding: "5px", width: "100%" }}>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										id="Product_Package"
										d="M19.7489 4.82064L10.2034 0.0478979C10.0755 -0.015966 9.92454 -0.015966 9.79682 0.0478979L0.251364 4.82064C0.0972727 4.89768 0 5.05496 0 5.22723V14.7727C0 14.945 0.0972727 15.1023 0.251136 15.1793L9.79659 19.952C9.86068 19.9841 9.93023 20 10 20C10.0698 20 10.1393 19.9841 10.2034 19.952L19.7489 15.1793C19.9027 15.1023 20 14.945 20 14.7727V5.22723C20 5.05496 19.9027 4.89768 19.7489 4.82064ZM10 0.962673L18.5291 5.22723L16.7045 6.13951L8.17568 1.87495L10 0.962673ZM13.0682 7.95769L4.53909 3.69314L7.15909 2.38313L15.6882 6.64769L13.0682 7.95769ZM13.4091 8.80361L16.1364 7.43996V10.8211L15.2641 10.1982C15.1652 10.1275 15.0416 10.0995 14.9218 10.1202C14.802 10.1413 14.6955 10.2093 14.6261 10.3093L13.4091 12.0677V8.80361ZM3.52273 4.20132L12.0516 8.46588L10 9.49179L1.47091 5.22723L3.52273 4.20132ZM0.909091 5.96269L9.54545 10.2809V18.8098L0.909091 14.4916V5.96269ZM10.4545 18.81V10.2809L12.5 9.25815V13.5227C12.5 13.7216 12.6291 13.8973 12.8189 13.9566C12.8639 13.9707 12.9093 13.9773 12.9545 13.9773C13.1009 13.9773 13.2418 13.9063 13.3284 13.7816L15.1114 11.2063L16.327 12.0745C16.4657 12.1734 16.648 12.1868 16.7991 12.1086C16.9502 12.0309 17.0455 11.8748 17.0455 11.7045V6.98542L19.0909 5.96269V14.4916L10.4545 18.81Z"
										fill="#828C96"
									/>
								</svg>

								<p>Inventory</p>
							</div>
						</NavLink>
						<NavLink to="/schedule">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={scheduleIcon} alt="home" />
								<p>Schedule</p>
							</div>
						</NavLink>
						<NavLink to="/leave">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={leaveIcon} alt="home" />
								<p>Leave</p>
							</div>
						</NavLink>
						<NavLink to="/history">
							<div style={{ padding: "5px", width: "100%" }}>
								<img style={{ width: "20px", height: "20px" }} src={historyIcon} alt="home" />
								<p>History</p>
							</div>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Inventory;
