import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'antd';
import Sidebar from '../../components/Sidebar';
import { useNavigate } from 'react-router-dom';

const UserBrand = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/editBrand`)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []); 

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Centrum', dataIndex: 'Centrum', key: 'Centrum' },
    { title: 'Scotts', dataIndex: 'Scotts', key: 'Scotts' },
    { title: 'Sensodyne', dataIndex: 'Sensodyne', key: 'Sensodyne' },
    { title: 'Caltrate', dataIndex: 'Caltrate', key: 'Caltrate' },
    { title: 'Polident', dataIndex: 'Polident', key: 'Polident' },
    { title: 'Panaflex', dataIndex: 'Panaflex', key: 'Panaflex' },
    {
      title: 'Actions',
      dataIndex: 'id', 
      key: 'actions',
      render: (id) => (
        <Button onClick={() => handleActionClick(id)}>Update</Button>
      ),
    },
    ];

    
  const handleActionClick = (id) => {
    navigate(`/admin/editUserBrand`, { state: { id } }); 
  };

  return (
    <div>
      <Sidebar />
      <div className="content">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default UserBrand;
