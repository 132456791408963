import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Form, Button, message, Upload, Image, Input } from 'antd';
import Sidebar from '../../components/Sidebar';

const EditPromoter = () => {
    const location = useLocation();
    const userId = location.state.id;

    const [form] = Form.useForm();
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        const fetchPromoterDetails = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getPromoter2`, { id: userId });
                const { promoter } = response.data;
                form.setFieldsValue({
                    name: promoter.name
                });
            } catch (error) {
                console.error('Error fetching promoter details:', error);
            }
        };

        fetchPromoterDetails();
    }, [userId, form]);

    const onFinish = async () => {
        try {
            if (imageFile) {
                await uploadImage(userId, imageFile);
                message.success('Image updated successfully');
            } else {
                message.error('No image found');
            }
        } catch (error) {
            console.error('Error updating promoter image:', error);
            // message.error('Error updating promoter image');
        }
    };

    const uploadImage = async (id, file) => {
        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('file', file);

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updatePromoterDetails`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            throw new Error('Error updating promoter image');
        }
    };

    const onImageChange = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    return (
        <div>
            <Sidebar />
            <div className="content">
                <Form
                    form={form}
                    name="editPromoterForm"
                    onFinish={onFinish}
                >
                    <Form.Item name="name" label="Name">
                        <Input />
                    </Form.Item>

                    <Form.Item name="image" label="Image">
                        <Upload
                            showUploadList={false}
                            customRequest={({ file, onSuccess }) => {
                                setImageFile(file);
                                onSuccess('ok');
                            }}
                            onChange={onImageChange}
                        >
                            <Button>Upload Image</Button>
                        </Upload>
                        {imageFile && <Image src={URL.createObjectURL(imageFile)} />}
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default EditPromoter;
